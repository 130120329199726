<template>
    <HeaderVue @togle-payment-popup="togglePaymentPopup()" @togle-profile-modal="toggleProfileModal()" :isAuthenticated="true"></HeaderVue>
    <main v-cloak>
      <router-view :userInfo="userInfo"></router-view>
    </main>
    <FooterVue></FooterVue>
    <modal-payment-popup v-show="isOpenedPaymentPopup" @togle-payment-popup="togglePaymentPopup()"/>
    <transition name="fade">
        <ModalProfileAction v-show="isOpenedProfileModal" @togle-profile-modal="toggleProfileModal()"/>
    </transition>
    <notifications position="top right" />
</template>

<script>
import axios from "axios"
import HeaderVue from '@/components/common/Header.vue'
import FooterVue from '@/components/common/Footer.vue'
import ModalPaymentPopup from '@/components/common/ModalPaymentPopup.vue'
import ModalProfileAction from '@/components/common/ModalProfileAction.vue'

export default {
    name: "constructor-layout",
    components: {
        HeaderVue,
        FooterVue,
        ModalPaymentPopup,
        ModalProfileAction,
    },
    data() {
        return {
            baseBackendUrl: process.env.VUE_APP_BASE_URL,
            baseUserIdent: process.env.VUE_APP_USER_IDENT,
                isOpenedPaymentPopup: false,
            isOpenedProfileModal: false,
            userInfo: {
                id: "4",
                email: "user@adsquiz.io",
                name: "",
                role: "user",
                profile: "freelance",
                phone: null,
                password: null,
                interface_lang: "EN",
                tarifs: [
                    {
                        id: "4460",
                        name: "Optimal",
                        price: 20,
                        is_active: true,
                        start_at: 1686843394,
                        end_at: 1689435394
                    }
                ],
                payments: [],
                created_at: 1663934006,
                quizCounter: 0,
                leadCounter: 0,
                isBlocked: false,
                bonuses: []
            },
        }
    },
    computed: {
        userSimbol() {
            return this.userInfo.name.slice(0, 1)
        },
        curentActiveTarif() {
            let lastActiveTariff = null;
            let activeTarifExists = false;
            let lastTarif = null;

            for (const tarif of this.userInfo.tarifs) {
                if (tarif.is_active) {
                    if (!lastActiveTariff || tarif.created_at > lastActiveTariff.created_at) {
                        lastActiveTariff = tarif;
                        activeTarifExists = true;
                    }
                }
                lastTarif = tarif;
            }

            if (!activeTarifExists && lastTarif) {
                return lastTarif;
            } else return lastActiveTariff
        }
        // userRequestedIdentificator() {
        //     if (localStorage.getItem('userMail')) {
        //         return 'email:"' + localStorage.getItem('userMail') + '"'
        //     } else return 'id:"1"'
        // },
    },
    methods: {
        togglePaymentPopup() {
            this.isOpenedPaymentPopup = !this.isOpenedPaymentPopup
        },
        toggleProfileModal() {
            this.isOpenedProfileModal = !this.isOpenedProfileModal
        },
        changeLocale(locale) {
            this.$i18n.locale = locale
            localStorage.setItem('locale_lang', locale);
            this.updateUserLang(this.$i18n.locale)
            this.mountedLang(this.$i18n.locale)
        },
        updateUserLang(locale) {
            var data = JSON.stringify({
                query: `mutation {
                    updateUser(
                        id: "` + this.userInfo.id + `",
                        interface_lang: "${locale}",
                    )
                    {
                        id
                        interface_lang
                    }
                }`,
                variables: {}

            });

            var config = {
                method: 'post',
                url: this.baseBackendUrl + '/graphql/',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then(() => {
                    
                })
                .catch (error => {
                    this.$notify({
                        title: 'Помилка в оновлені даних: "' + this.userInfo.email,
                        type: 'error'
                    });
                    console.log(error);
                })
        },
        activeLang(locale) {
            let locale_lang = this.$i18n.locale
            if (locale == locale_lang) {
                return 'active'
            } else {
                return ''
            }
        },
        changeLocaleAfterLogin(interface_lang) {
            this.$i18n.locale = interface_lang
        },
        giveProfileInfo() {
            var queryData = JSON.stringify({
                query: `query { 
                users (`+ this.baseUserIdent +`) {
                    id
                    email
                    name
                    isBlocked
                    profile
                    role
                    interface_lang
                    tarifs { 
                        id
                        name
                        price
                        is_active
                        start_at
                        end_at
                    }
                }
            }`,
                variables: {}
            });

            var reciveQuizzes = {
                method: 'post',
                url: this.baseBackendUrl + '/graphql',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: queryData,
                withCredentials: true
            };

            axios(reciveQuizzes)
                .then(response => {
                    this.userInfo = response.data.data.users[0]
                })
                .catch(function (error) {
                    console.log(error);
                })

        },
        async logout() {
            var config = {
                method: 'post',
                url: this.baseBackendUrl + '/logout',
                headers: {
                    'Accept': 'application/json'
                },
            };
            axios(config)
                .then(() => {
                    // console.log(JSON.stringify(response.data));
                    localStorage.setItem('isLogin', '0')
                    // eslint-disable-next-line
                    HelpCrunch('logout');
                    this.$router.push({name: 'login-view'})
                    // this.deleteCookie('adsquiz_session')
                    // this.deleteCookie('XSRF-TOKEN')
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        mountedLang(interfaceLang) {
            var htmlElement = document.getElementsByTagName('html')[0]
            htmlElement.setAttribute('lang', interfaceLang);
        },
        
    },
    mounted() {
        this.isOpenedProfileModal = false
        this.giveProfileInfo()
        // let locale_lang = localStorage.getItem('locale_lang')
        
    }
}

</script>

<style>
[v-cloak] {
    opacity: 0;
}

.quiz-action_copy {
    background: none;
    border: none;
    color: #66757F;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    padding: 0 0 0 25px;
    background-image: url(img/icons/quiz-copy.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 18px;
    cursor: pointer;
    width: 140px;
    text-align: center;
}

.quiz-action_delete {
    background: none;
    border: none;
    color: #66757F;
    font-size: 12px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    padding: 0 0 0 25px;
    background-image: url(img/icons/quiz-delete.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    width: 84px;
    text-align: center;
}

.quiz-action_copy:hover {
    color: #324AC7;
    font-weight: 700;
    background-image: url(img/icons/quiz-copy-hover.png);
}

.quiz-action_delete:hover {
    color: #324AC7;
    font-weight: 700;
    background-image: url(img/icons/quiz-delete-hover.png);
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #f5f5f5;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 40px;
  }
  
  .slider.round:before {
    border-radius: 40px;
  }

.constructor-board .container {
    display: flex;
    /* justify-content: space-between; */
    height: 100%;
    padding: 0;
}

.constructor_control {
    height: 100%;
    width: 356px;
    background: #fff;
    border-radius: 00px;
    /* padding: 24px 24px 16px 28px; */
}
.constructor-board_back_to_workboard_wrap,
.constructor-board_back_to_settings_wrap {
    padding: 16px 28px 16px 28px;
    display: flex;
    justify-content: space-between;
}
.c-ctrl_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 24px 16px 28px;
}
/* 
.back-to-work-board {
    background-image: url(img/icons/back-solid.svg);
    background-position: center center;
    background-size: 15px;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    font-weight: 400;
    margin-right: 24px;
    width: auto!important;
} */


.c-ctrl_q-name {
    border-radius: 8px;
    padding: 12px 40px 12px 16px;
    height: 44px;
    width: 100%;
}

.c-ctrl_q-name:focus,
.c-ctrl_q-name:active {
    outline: none;
    color: #282f35;
}

.quiz_name_editable {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 38px;
    bottom: 30px;
    background-color: transparent;
    background-image: url(img/icons/save_input_change.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
}

.c-ctrl_q-name:read-only+.quiz_name_editable {
    background-image: url(img/icons/quiz_name_edit.svg);
}

.c-ctrl_q-int_input {
    border-radius: 8px;
    padding: 12px 40px 12px 16px;
    height: 44px;
    width: 100%;
}

.c-ctrl_q-int_input:focus,
.c-ctrl_q-int_input:active {
    outline: none;
    color: #282f35;
}

.quiz_input_editable {
    position: absolute;
    padding: 0;
    width: 0;
    height: 16px;
    right: 16px;
    background-color: transparent;
    background-image: url(img/icons/save_input_change.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    opacity: 0;
}

.c-ctrl_q-int_input.change+.quiz_input_editable {
    width: 16px;
    opacity: 1;
}


.c-ctrl_main-link {
    width: 100%;
    padding: 0 24px 0 28px;
}

.c-ctrl_main-link nav {
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
}

.c-ctrl_main-link nav a {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0);
    padding: 6px 8px;
    text-align: center;
    width: inherit;
    font-weight: 700;
}

.c-ctrl_main-link nav a.active {
    background: #324AC7;
    color: #fff;
}

.c-ctrl_body {
    /* margin-top: 16px; */
    height: calc(100% - 228px);
    display: flex;
    flex-direction: column;
    padding: 0 0 0 28px;
    transition: all 0.3s ease;
}
#settings-board .c-ctrl_body {
    height: calc(100% - 156px);
}

.c-ctrl-d {
    height: calc(100% - 181px);
}

.c-ctrl_body .ps{
    padding-right: 24px;
    transition: all 0.5s ease;
}


.c-ctrl-section {
    margin-top: 16px;
    padding-bottom: 16px
}

.c-ctrl-section>h5 {
    margin-bottom: 8px;
}

.c-ctrl_body h5 {
    margin-bottom: 8px;
}

.c-ctrl-delete {
    width: 12px;
    height: 12px;
    border: none;
    background-color: transparent;
    background-image: url(img/icons/delete.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    font-size: 12px;
    position: absolute;
}
.c-ctrl-delete.c-ctrl-step_photo-delete {
    right: 10px;
}
.c-ctrl-delete.delete_answer {
    right: 10px;
}

.c-ctrl-delete:disabled {
    background-image: url(img/icons/reset_disabled.svg);
    cursor: default;
}

.c-ctrl-reset {
    padding-left: 20px;
    width: auto;
    height: 16px;
    border: none;
    background-color: transparent;
    background-image: url(img/icons/reset.svg);
    background-position: 2px center;
    background-repeat: no-repeat;
    background-size: 16px;
    font-size: 12px;
}

.c-ctrl-reset:disabled {
    background-image: url(img/icons/reset_disabled.svg);
    cursor: default;
}

.c-ctrl_footer {
    padding: 16px 24px 16px 28px;
}

/* ==================================== */
.c-ctrl-body_steps {
    height: calc(100% - 45px);
    margin: 0;
}

.c-ctrl-s_steps {
    height: 100%;
    padding: 0;
    overflow: auto;
}

.c-ctrl-s_steps-wrap {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 0;
    position: relative;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

.c-ctrl-s_steps-wrap li {
    display: flex;
    justify-content: space-between;
    height: 22px;
    color: #dadada;
    background: rgba(255, 255, 255, 0);
    border: 1px solid rgba(255, 255, 255, 0);
    margin-bottom: 8px;
}

.c-ctrl-s_steps-wrap li a {
    color: #808080;
}

.c-ctrl-s_steps-wrap li:hover a,
.c-ctrl-s_steps-wrap li.active a {
    color: #000;
}

.c-ctrl-s_steps-wrap li a:visited {
    color: #808080;
}
.c-ctrl-s_steps-wrap li:hover a:visited {
    color: #000;
}
.c-ctrl-s_steps-wrap li.active a:visited {
    color: #000;
}

.c-ctrl-s_step-numb {
    margin-right: 8px;
}

.c-ctrl-s_actions {
    width: 70px;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: all 0.2s ease;
}

.start_page_action {
    justify-content: flex-end;
}

.finish_page_action {
    justify-content: flex-end;
}

.c-ctrl-s_actions input,
.c-ctrl-s_actions button,
.c-ctrl-s_actions a {
    display: block;
    width: 18px;
    height: 18px;
    margin-right: 8px;
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px;
}

.c-ctrl_header-accordion:hover .c-ctrl-s_actions {
    opacity: 1;
    transition: all 0.2s ease;
}

.c-ctrl-s_name {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 75px);
}

.c-ctrl_header-accordion {
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    border-radius: 4px;
}

.accordion-item.active .c-ctrl_header-accordion {
    border-radius: 4px;
    cursor: pointer;
}

.accordion-item .accordion-chevron {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.accordion-item.active .accordion-chevron {
    transform: rotate(180deg);
    transition: all 0.2s ease;
}
.accordion-item.active .accordion-chevron path {
    fill: #808080;
}

.accordion-item button {
    cursor: pointer;
}

.ui-state-highlight { 
    height: 22px;
    line-height: 22px; 
    background: rgba(255, 255, 255, 0);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
}

.c-ctrl-s_actions input:hover {
    box-shadow: none;
}

span.c-ctrl-s_move {
    display: inline-block;
    width: 18px;
    min-width:18px;
    margin-right: 8px;
    height: 18px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url(img/icons/move-step.svg);
    cursor: move;
    /* fill: rgb(40.000001%, 45.882353%, 49.803922%); */
}
span.c-ctrl-s_move:hover {
    cursor: move;
}

span.c-ctrl-s_move.inactive {
    background-image: url(img/icons/inactive_move-step.svg);
    cursor: default;
}
span.c-ctrl-s_move.inactive:hover {
    cursor: default;
}

.header-accordion_left-side {
    display: flex;
}
.header-accordion_right-side {
    display: flex;
}

.header-accordion_left-side p {
    display: -webkit-box;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 260px;
}

input.c-ctrl-s_edit,
a.c-ctrl-s_edit {
    background-image: url(img/icons/edit.svg);
}

input.c-ctrl-s_reload {
    background-image: url(img/icons/reload-change.svg);
}

input.c-ctrl-s_delete {
    background-image: url(img/icons/delete.svg);
}

input.c-ctrl-s_hidden {
    background-image: url(img/icons/eye_open_blue.svg);
}

input.c-ctrl-s_hidden.hidden {
    background-image: url(img/icons/eye_sleepy_blue.svg);
}

.c-ctrl-s_add-btn-wrap {
    position: relative;
}

input.c-ctrl-s_add-step {
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
    padding: 5px 5px 5px 20px;
    position: relative;
    color: #000;
}

input.c-ctrl-s_add-step:hover {
    box-shadow: none;
}

.c-ctrl-s_add-btn-wrap::before {
    position: absolute;
    display: block;
    width: 15px;
    height: 28px;
    content: '+';
    left: 0;
    top: 0;
    color: #dadada;
    font-size: 25px;
    line-height: 25px;
    font-weight: 300;
}
/* ===================================== */


.c-ctrl-body_design {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin-top: 24px;
}
.c-ctrl-body_design .c-ctrl-d_body {
    /* height: calc(100% - 50px); */
    height: 100%;
}

.c-ctrl-s_colors {
    height: calc(100% - 94px);
    overflow: hidden;
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
}

/* .c-ctrl-s_colors h5 {
    margin-bottom: 16px;
} */

.c-ctrl-s_colors .scroll-pane{
    height: calc(100%);
    /* overflow: hidden; */
    /* margin: 0; */
    /* padding-bottom: 20px; */
}

.c-ctrl-s_colors input[type="radio"],
.c-ctrl-s_colors input[type="checkbox"] {
    display: none;
}

.c-ctrl-s_default_themes-wrap {
    display: flex;
    flex-direction: column;
}

.c-ctrl-s_colors .c-ctrl-s_item-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    padding: 10px;
    border: 1px solid #E6E6E6;
    border-radius: 8px;
    margin-bottom: 8px;
}
.c-ctrl-s_colors .c-ctrl-s_item-wrap .name_theme_group {
    width: calc(100% - 88px);
    display: block;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
}

.c-ctrl-s_colors ul {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    /* justify-content: space-between; */
    padding: 0;
}

.c-ctrl-s_colors ul li {
    border-radius: 4px;
    width: 100%;
    list-style-type: none;
}

.c-ctrl-s_colors ul li+li {
    margin-bottom: 3px;
}

.c-ctrl-s_colors ul li>label {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 8px;
    width: 100%;
    height: 100%;
}

.c-ctrl-s_colors ul li label {
    display: flex;
    gap: 8px;
    width: auto;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
}
.c-ctrl-s_colors ul li label span {
    width: 16px;
    height: 16px;
    border: none;
    border-radius: 4px;
    /* margin: 5px; */
    background-color: #282f35;
}



.c-ctrl-s_colors span.white {
    background-color: #FFF;
    border: 1px solid #ECECEC;
}
.c-ctrl-s_colors span.gainsborough {
    background-color: #E6E6E6;
}
.c-ctrl-s_colors span.black {
    background-color: #000000;
}
.c-ctrl-s_colors span.azure_blue {
    background-color: #324AC7;
}
.c-ctrl-s_colors span.moderate_purplish_red {
    background-color: #9D324C;
}
.c-ctrl-s_colors span.violet_blue_crayola {
    background-color: #463CB5;
}
.c-ctrl-s_colors span.wet_rainforest {
    background-color: #1C8F6A;
}
.c-ctrl-s_colors span.black_gray {
    background-color: #21252A;
}
.c-ctrl-s_colors span.smoky_white {
    background-color: #F2F2F2;
}
.c-ctrl-s_colors span.lavender {
    background-color: #EFF5FA;
}
.c-ctrl-s_colors span.black_gentian_blue {
    background-color: #21252A;
}
.c-ctrl-s_colors span.blue_lilac {
    background-color: #6F21D8;
}
.c-ctrl-s_colors span.black_deep_bluish_green {
    background-color: #21252A;
}
.c-ctrl-s_colors span.opal_green {
    background-color: #5F9B8C;
}
.c-ctrl-s_colors span.dusty_blue {
    background-color: #E5F6EE;
}
.c-ctrl-s_colors span.light_bluish_green {
    background-color: #5F9B8C;
}
.c-ctrl-s_colors span.almond_crayola {
    background-color: #F3E1E1;
}
.c-ctrl-s_colors span.light_cherry {
    background-color: #DE446A;
}
.c-ctrl-s_colors span.periwinkle_crayola_lilac {
    background-color: #D8D8EF;
}
.c-ctrl-s_colors span.intense_purplish_blue {
    background-color: #4D4793;
}
.c-ctrl-s_colors span.periwinkle_crayola_aqua {
    background-color: #D6E4F1;
}
.c-ctrl-s_colors span.green_blue_crayola {
    background-color: #095EAC;
}
.c-ctrl-s_colors span.jazz_jam {
    background-color: #C33764;
}
.c-ctrl-s_colors span.midnight_black {
    background-color: #1D2671;
}
.c-ctrl-s_colors span.blackish_blue {
    background-color: #1E2029;
}
.c-ctrl-s_colors span.very_pale_blue {
    background-color: #BDC3C7;
}
.c-ctrl-s_colors span.slate_gray {
    background-color: #2C3E50;
}
.c-ctrl-s_colors span.slate_gray_blue {
    background-color: #2F3E4C;
}
.c-ctrl-s_colors span.turquoise_blue_crayola {
    background-color: #74EBD5;
}
.c-ctrl-s_colors span.light_steel_blue {
    background-color: #ACB6E5;
}
.c-ctrl-s_colors span.black_pearlescent_opal {
    background-color: #0F2027;
}
.c-ctrl-s_colors span.moderate_grenish_blue {
    background-color: #2C5364;
}
.c-ctrl-s_colors span.pearlescent_gentian_blue {
    background-color: #2B5876;
}
.c-ctrl-s_colors span.moderate_purplish_blue {
    background-color: #4E4376;
}
.c-ctrl-s_colors span.sapphire_blue {
    background-color: #282A3D;
}
.c-ctrl-s_colors span.gray_blue {
    background-color: #262730;
}

.c-ctrl-s_colors span.pastel_yellow {
    background-color: #EF9645;
}

.c-ctrl-s_colors span.basalt_gray {
    background-color: #485655;
}

.c-ctrl-s_form {
    padding-right: 24px;
    margin-top: 0;
}

.c-ctrl-s_form h5 {
    padding-top: 16px;
}

.c-ctrl-s_form-wrap {
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

.c-ctrl-s_form-wrap>label {
    cursor: pointer;
    width: 100%;
}

.c-ctrl-s_form-wrap>label label {
    width: 100%;
    height: 100%;
    padding: 8px 24px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
}

.c-ctrl-s_form input {
    display: none;
}

.c-ctrl-section .c-ctrl-s_subtitle {
    width: 100px;
}

.c-ctrl-section .c-ctrl-s_item-wrap {
    display: flex;
}

/* .c-ctrl-s_form-wrap>label {
    width: 45%;
    display: flex;
} */

.c-ctrl-s_form-wrap .c-ctrl-s_item-wrap {
    display: flex;
    justify-content: center;
}

.c-ctrl-section h6.c-ctrl-s_subtitle {
    display: block;
    width: auto;
    font-weight: 500;
}

.c-ctrl-s_form-wrap .c-ctrl-s_item-wrap.active span {
    border-color: #324AC7;
}

.c-ctrl-s_form-wrap .c-ctrl-s_item-wrap span {
    display: block;
    width: 15px;
    height: 15px;
    border-width: 2px;
    border-style: solid;
    border-bottom: none;
    border-left: none;
    margin-right: 7px;
}

.c-ctrl-s_form-round span {
    border-top-right-radius: 6px;
}


.c-ctrl-s_t-size-wrqp input {
    display: none;
}

.c-ctrl-s_t-size-wrqp label {
    padding: 0 7px;
    font-weight: 400;
    color: #000;
    font-size: 14px;
    background: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
}

.c-ctrl-s_t-size-wrqp input.active,
.c-ctrl-s_t-size-wrqp input:hover,
.c-ctrl-s_t-size-wrqp input:active,
.c-ctrl-s_t-size-wrqp input:focus,
.c-ctrl-s_t-size-wrqp input:checked+label {
    font-weight: 900;
    color: #324AC7;
    box-shadow: none;
}
/* 
.c-ctrl-s_t-uppercase-wrqp {

}

.c-ctrl-s_t-color-wrqp {

} */
.c-ctrl-s_t-color-wrqp input {
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 8px;
    /* background: #000; */
}

.c-ctrl-s_txt-size-wrqp input {
    font-weight: 400;
    color: #000;
    font-size: 14px;
    background: rgba(255, 255, 255, 0);
    border: none;
    box-shadow: none;
}

.c-ctrl-s_txt-size-wrqp input.active,
.c-ctrl-s_txt-size-wrqp input:hover,
.c-ctrl-s_txt-size-wrqp input:active,
.c-ctrl-s_txt-size-wrqp input:focus,
.c-ctrl-s_txt-size-wrqp input:checked {
    font-weight: 900;
    color: #324AC7;
    box-shadow: none;
}


/* ======================= */
.constructor-body_slide.corners_square {
    border-radius: 0;
}

.constructor-body_slide.corners_square .block_img img {
    border-radius: 0;
}

.constructor-body_slide.corners_square .slide_progresbar {
    border-radius: 0;
}

.constructor-body_slide.corners_square .progresbar_line {
    border-radius: 0;
}

.corners_square .quiz_nav .step_back {
    border-radius: 0;
}

.corners_square .quiz_nav .step_forvard {
    border-radius: 0;
}

.step-first-slide.corners_square .bonus-block {
    border-radius: 0;
}

.step-first-slide.corners_square .start {
    border-radius: 0;
}

.corners_square .answer_wrap {
    border-radius: 0;
}

.step-any-slide.format_custom.corners_square>img {
    border-radius: 0;
}

.corners_square .answer_blank_input[type=text] {
    border-radius: 0;
}

.corners_square .slide_form_inputs .slide_form_input {
    border-radius: 0;
}

.corners_square .quiz_nav .step_send,
.corners_square .quiz_nav input[type=button].step_send {
    border-radius: 0;
}

.step-finish-slide.corners_square>img {
    border-radius: 0;
}

.corners_square .step-finish_go-to-url {
    border-radius: 0;
}


/* ==================================== */

.c-ctrl-body_settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
}

.c-ctrl-body_settings .c-ctrl-s_body {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
}

.c-ctrl-body_settings .scroll-pane {
    height: 100%;
}

.c-ctrl-body_settings .c-ctrl-s_scroll-body .ps {
    left: 0;
    width: 100%;
    padding: 0 28px;
}

.c-ctrl-s_body a {
    display: block;
    font-weight: 300;
    font-size: 14px;
    color: #66757F;
    margin-bottom: 20px;
}

.c-ctrl-s_body .ql-editor a {
    display: inline;
}

.settings_point_wrap {
    margin-bottom: 20px;
}
.settings_point_wrap {
    margin-bottom: 20px;
}

.c-ctrl-s_body .settings_point_wrap>input {
    display: block;
    border: none;
    background: transparent;
    color: #66757f;
    font-size: 14px;
}
.c-ctrl-s_body .settings_point_wrap label {
    display: block;
    font-weight: 300;
    font-size: 14px;
    color: #66757F;
}
/* .c-ctrl-s_body .settings_point_wrap input.active, */
.c-ctrl-s_body .settings_point_wrap>label:hover,
.c-ctrl-s_body .settings_point_wrap>input:active+label,
.c-ctrl-s_body .settings_point_wrap>input:checked+label,
.c-ctrl-s_body .settings_point_wrap>label:focus,
.c-ctrl-s_body .settings_point_wrap>a:hover {
    font-weight: 700;
    color: #324AC7;
}
.c-ctrl-s_body .settings_point_wrap input~.settings_subpoint {
    display: none
}
.c-ctrl-s_body .settings_point_wrap input:checked~.settings_subpoint {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}
.settings_subpoint input {
    height: 40px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    width: 200px;
    padding: 12px 16px;
}
.settings_point_integration {
    margin-bottom: 0;
    padding: 8px 0;
}
.settings_point_integration label,
.settings_advanced-settings button {
    padding: 12px 8px;
    display: block;
    background: none;
    border: none;
    width: 100%;
    text-align: left;
}
.embed_quiz_checkbox_opener {
    display: none!important;
}

.any_integrations_point+.any_integrations_point{
    margin-top: 15px;
}

/* .constructor_settings_body {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: calc(100% - 356px);
} */
.some_extra_popup {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.close_all_extra_popup {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
}
.select_embed_variant {
    position: relative;
    padding: 0 16px;
    width: 240px;
    height: 100%;
    z-index: 99;
}
.select_embed_variant .c-ctrl-s_scroll-body {
    height: 100%;
    margin-left: -16px;
}

.select_embed_variant .c-ctrl-s_scroll-body .ps {
    width: 100%;
    left: 16px;
    padding: 0 16px 0 0;
}

.select_embed_variant h6 {
    margin-bottom: 16px;
    margin-top: 14px;
    font-weight: 700;
}
.select_embed_variant .variants_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding-bottom: 14px;
}

.select_embed_variant .variant_item {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    text-align: start;
}

.select_embed_variant .img_wrap {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #F2F2F2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.select_embed_variant .img_wrap img {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 8px;
    object-fit: cover;
}

.select_embed_variant .img_wrap>span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 56, 56, 0.7);
    backdrop-filter: blur(1.5px);
}

.select_embed_variant .img_wrap>span>span {
    width: 24px;
    height: 24px;
    box-shadow: none!important;
}

.select_embed_variant .img_wrap>span>span img {
    width: 100%;
    height: 100%;
    box-shadow: none!important;
}

.add_section_variant {
    position: relative;
    padding: 0 16px;
    width: 240px;
    height: 100%;
    z-index: 99;
}
.add_section_variant .c-ctrl-s_scroll-body {
    height: 100%;
    margin-left: -16px;
}

.add_section_variant .c-ctrl-s_scroll-body .ps {
    width: 100%;
    left: 16px;
    padding: 0 16px 0 0;
}

.add_section_variant h6 {
    margin-bottom: 16px;
    margin-top: 14px;
    font-weight: 700;
}
.add_section_variant .variants_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding-bottom: 14px;
}

.add_section_variant .variant_item {
    cursor: pointer;
    position: relative;
    display: block;
    width: 100%;
    text-align: start;
    padding: 0;
    border: none;
    background-color: transparent;
}

.add_section_variant .img_wrap {
    width: 100%;
    height: 100%;
    padding: 0;
    background: #F2F2F2;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.add_section_variant .img_wrap img {
    width: 100%;
    height: 100%;
    box-shadow: none;
    border-radius: 8px;
    object-fit: cover;
}

.add_section_variant .img_wrap>span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(56, 56, 56, 0.7);
    backdrop-filter: blur(1.5px);
}

.add_section_variant .img_wrap>span>span {
    width: 24px;
    height: 24px;
    box-shadow: none!important;
}

.add_section_variant .img_wrap>span>span img {
    width: 100%;
    height: 100%;
    box-shadow: none!important;
}

.spin {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.select_embed_variant .img_wrap>span>p {
    text-align: center;
    color: #FFF;
}

.select_embed_variant .variant_item p {
    margin-top: 4px;
}


.select_embed_variant .variant_item:hover img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.add_section_variant .img_wrap>span>p {
    text-align: center;
    color: #FFF;
}

.add_section_variant .variant_item p {
    margin-top: 4px;
}


.add_section_variant .variant_item:hover img {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* .constructor_vidget_body {
    display: flex;
    align-items: center;
    width: calc(100% - 350px);
    height: calc(100% - 60px);
    padding: 0px 0 0 40px;
}

.constructor_vidget_body img {
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

/* =========================== */

.c-ctrl_sub_settings .c-ctrl_header {
    border-bottom: 1px solid #F5F5F5;
    padding-bottom: 12px;
}

.c-ctrl_sub_settings .c-ctrl-s_scroll-body {
    height: 100%;
}

.c-ctrl-s_scroll-body .ps {
    width: calc(100% + 20px);
    left: -10px;
    padding: 0 10px;
}

.c-ctrl_sub_settings .c-ctrl_body {
    height: calc(100% - 64px);
    /* padding-right: 10px; */
}

/* .c-ctrl-body_integration .c-ctrl-s_scroll-body .ps {
    padding-right: 28px;
} */
.c-ctrl-body_integration {
    border-top: var(--border-default);
}
.c-ctrl-section.c-ctrl-s_vidget {
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 16px;
}

.c-ctrl_sub_settings .c-ctrl_body.full_height {
    height: 100%;
}
.c-ctrl_sub_settings .c-ctrl_body.full_height_integration {
    height: 100%;
}

.sub_settings_title_row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.sub_settings_title_row h5 {
    display: block;
}

.sub_settings_title_row .sub_settings_tip_wrap {
    width: 100px;
}

.sub_settings_tip_wrap label input {
    display: none;
}

.sub_settings_tip_wrap label {
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0;
}

.sub_settings_tip_wrap label span {
    background: #E6E6E6;
    border: 1px solid #E6E6E6;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    color: #66757F;
    font-weight: 900;
    line-height: 16px;
    font-size: 12px;
    text-align: center;
}

.sub_settings_tip_wrap label p {
    width: calc(100% - 15px);
    color: #8C979F;
    line-height: 12px;
    font-size: 12px;
    text-align: center;
}

.sub_settings_tip_text {
    background: #F5F5F5;
    border-radius: 10px;
    padding: 20px 25px 20px 15px;
    height: 100%;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    transition: all 0.5s ease;
    margin-bottom: 24px;
}

.sub_settings_tip_text.disactive {
    opacity: 0;
    padding: 0px 25px 0px 15px;
    height: 0px;
    max-height: 0px;
    transition: all 0.5s ease;
    margin-bottom: 0;
}
.sub_settings_tip_text.disactive .sub_settings_tip_text_point_wrap {
    margin-bottom: 0;
}
.sub_settings_tip_text.disactive h6 {
    opacity: 0;
    margin: 0;
    height: 0%;
    max-height: 0%;
    transition: all 0.3s ease;
    line-height: 0px;
}
.sub_settings_tip_text.disactive p {
    opacity: 0;
    margin: 0;
    height: 0%;
    max-height: 0%;
    transition: all 0.3s ease;
    line-height: 0px;
}
.sub_settings_tip_text.disactive p+p {
    opacity: 0;
    margin: 0;
    transition: all 0.3s ease;
}
/* .sub_settings_tip_text a{
    margin: 10px 0 0 0;
} */
.sub_settings_tip_text.disactive a{
    margin: 0;
    height: 0%;
}
.c-ctrl-s_telegram .sub_settings_tip_text.disactive p+p {
    opacity: 0;
    margin: 0;
    transition: all 0.3s ease;
}
.sub_settings_tip_text_point_wrap+.sub_settings_tip_text_point_wrap {
    margin-top: 20px;
}
.sub_settings_tip_text h6 {
    opacity: 1;
    margin: 0 0 8px 0;
    height: auto;
    max-height: 100%;
    transition: all 0.3s ease;
    line-height: 18px;
    font-size: 14px;
    font-weight: 700;
    color: #000;
}
.sub_settings_tip_text p+h6 {
    margin: 15px 0 10px 0;
}
.sub_settings_tip_text p {
    font-size: 12px;
    color: #313131;
    line-height: 17px;
    height: 100%;
    transition: all 0.3s ease;
}

.sub_settings_tip_text p+p,
.sub_settings_tip_text p+a,
.sub_settings_tip_text a+p {
    margin-top: 4px;
}

.sub_settings_tip_text p a {
    display: inline-block;
    color: #324ac7;
    font-weight: 700;
}

p.integration_about_text a {
    display: inline-block;
    color: #324ac7;
    font-weight: 700;
}

.sub_settings_tip_text p+a {
    display: inline-block;
    color: #324ac7;
    font-weight: 700;
}

.delete_one-of-line-integration {
    height: auto!important;;
}

.add_new_integration {
    margin-top: 16px;
    width: auto!important;;
}

.c-ctrl-s_telegram .sub_settings_tip_text p+p {
    margin-top: 15px;
}

.sub_settings_point_wrap h5 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 9px;
}
.text_email_header {
    margin-bottom: 20px;
    font-size: 12px;
    color: #313131;
}

.sub_settings_point_wrap .sub_settings_point {
    display: flex;
    align-items: center;
    position: relative;
}

.sub_settings_point input[type=text] {
    width: 100%;
}

.sub_settings_point_wrap+.sub_settings_point_wrap {
    margin-top: 20px;
    padding-bottom: 20px;
}

.republished_point {
    font-size: 12px;
    color: #324AC7;
    display: block;
    max-width: 100%;
    background: none;
    border: none;
    padding: 0;
    margin-top: 20px;
}

.republished_quiz:hover {
    transform: none;
    box-shadow: none;
}

.sub_settings_point_wrap .vidget-code_point {
    position: relative;
}

.vidget_poin p{
    color: #313131;
    font-size: 12px;
    margin-bottom: 10px;
}

.sub_settings_point_wrap .vidget-code_point textarea.vidget_code_body {
    height: 70px;
    margin-bottom: 40px;
}

.sub_settings_point_wrap .vidget-code_point .copy_vidget_code {
    position: absolute;
    display: block;
    top: 5px;
    right: 7px;
    border:none;
    width: 17px;
    height: 17px;
    background-color: transparent;
    background-size: contain;
    background-image: url(img/icons/vidget-code-copy.svg);
    /* background-image: url('img/icons/vidget-code-copy.svg'); */
}

.sub_settings_point_send_to_dev .developer_email{
    border: 1px solid #DADADA;
    border-radius: 10px;
    color: #313131;
    width: 100%;
    padding: 15px 20px;
    font-size: 16px;
    margin-bottom: 10px;
}

.sub_settings_point_send_to_dev_action {
    display: flex;
}

.sub_settings_point_send_to_dev_action select {
    width: 74px;
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 15px 15px;
    margin-right: 10px;
}

.sub_settings_point_send_to_dev_action .btn-send-code {
    width: calc(100% - 84px);
    border: 1px solid #324AC7;
    border-radius: 10px;
    color: #FFF;
    font-weight: 700;
    padding: 15px 20px;
    background: #324AC7;
    text-align: center;
}

.vidget-popup_setting_switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.sub_settings_point_wrap .sub_settings_point h5 {
    margin-bottom: 0;
}

.sub_settings_point_wrap .vidget-popup_setting_input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    position: relative;
}

.sub_settings_point.vidget-popup_setting_input input[type=text] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 7px;
}

.sub_settings_point.vidget-button_setting_input input[type=text] {
    width: 100%;
    max-width: 100%;
    margin-bottom: 7px;
}

.vidget-button_setting_switcher {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.vidget-popup_setting_input span {
    position: absolute;
    right: 10px;
    top: 16px;
}

.delete_integration {
    display: block;
    width: 18px;
    height: 18px;
    border: 1px solid rgba(255, 255, 255, 0);
    background: rgba(255, 255, 255, 0);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 20px;
    background-image: url(img/icons/delete-black.svg);
    fill: #000;
    stroke: #000;
    color: #000;
    /* background-color: #000;
        -webkit-mask-image: url(img/icons/delete.svg);
        mask-image: url(img/icons/delete.svg); */
}

.add_more_integration {
    margin-top: 20px;
    color: #000;
    font-weight: 600;
    font-size: 12px;
    padding: 12px 15px;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    background: transparent;
}

.add_more_integration:hover {
    transform: none;
    background: #f0eeee;
}

a.telegram_chatbot {
    font-weight: 700;
    color: #324AC7;
    font-size: 12px;
}


.integrations_points_wrap h5 {
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 16px;
}

.seting_tip_wrap {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px
}

.integration_point {
    display: flex;
    align-items: center;
    margin: 16px 0;
}

.integration_point button {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px 6px;
}

.integration_img {
    width: 20px;
    height: 20px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 13px;
}





.integration_googletagmanager .integration_img {
    background-image: url(img/icons/gtm_active.svg);
}

.integration_googleanalytics .integration_img {
    background-image: url(img/icons/g_analytics_active.svg);
}

.integration_facebookpixel .integration_img {
    background-image: url(img/icons/fb_pixel_active.svg);
}

.integration_facebookconvapi .integration_img {
    background-image: url(img/icons/integration_fbcapi_active.svg);
}

.integration_tiktokpixel .integration_img {
    background-image: url(img/icons/tt_pixel_active.svg);
}

.integration_renovatehub .integration_img {
    background-image: url(img/icons/integration_renovatehub_active.svg);
}

.integration_webhook .integration_img {
    background-image: url(img/icons/integration_webhook_active.svg);
}

.integration_zapier .integration_img {
    background-image: url(img/icons/integration_zapier_active.svg);
}

.integration_apixdrive .integration_img {
    background-image: url(img/icons/integration_apix-drive_active.svg);
}

.integration_make .integration_img {
    background-image: url(img/icons/integration_make_active.jpg);
}

.integration_sendpulse .integration_img {
    background-image: url(img/icons/integration_sendpulse_active.svg);
}

.integration_email .integration_img {
    background-image: url(img/icons/integration_email_active.svg);
}

.integration_telegrambot .integration_img {
    background-image: url(img/icons/integration_telegram_active.svg);
}

.integration_pipedrive .integration_img {
    background-image: url(img/icons/integration_pipedrive_active.svg);
}

.integration_remonline .integration_img {
    background-image: url(img/icons/integration_remonline_active.svg);
}

.integration_keycrm .integration_img {
    background-image: url(img/icons/integration_keycrm_active.png);
}

.integration_crmbitrix .integration_img {
    background-image: url(img/icons/integration_bitrix24_active.svg);
}



.integration_point span.active_integration {
    display: block;
    padding: 4px 8px;
    line-height: 1em;
    border-radius: 100px;
    transition: all 0.2s ease;
}

.integration_point.active_integration span.active_integration {
    opacity: 1;
    transition: all 0.2s ease;
}

/* ============================ */

.constructor_control_step .c-ctrl_header {
    padding-bottom: 10px;
    border-bottom: 1px solid #F5F5F5;
}

.header-steps-counter {
    display: flex;
    align-items: center;
    color: #DADADA;
    font-weight: 300;
}

.header-steps-counter span {
    padding: 0 3px;
}

/* .c-ctrl_body_step-wrap {
    height: calc(100% - 50px);
    overflow: hidden;
} */

.c-ctrl_body_step {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.c-ctrl_body_step>.c-ctrl-s_body {
    height: calc(100% - 50px);
    overflow:visible;
}

/* ========================= */

.c-ctrl-sf_item-wrap {
    display: flex;
    align-items: center;
}

.c-ctrl-sf_item-wrap_format {
    flex-direction: column;
    align-items: flex-start;
}

.c-ctrl-sf_item-wrap textarea {
    width: 100%;
    height: 80px;
    border-radius: 8px;
    padding: 14px 24px;
}

.c-ctrl_edit-photo {
    margin-top: 16px;
    width: 100%;
}

.c-ctrl_edit-photo .change_photo_position_button {
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    color: #000;
    background: transparent;
    font-weight: 700;
    padding: 7px 9px 8px 35px;
    background-image: url(img/icons/edit-photo-icon.svg);
    background-position: 9px center;
    background-repeat: no-repeat;
    background-size: 14px;
}

input[type="button"].change_photo_position_button:hover {
    cursor: pointer;
    box-shadow: 1px 1px 20px -5px rgb(0 0 0 / 16%);
    transform: none;
}


.c-ctrl_edit-photo .popper {
    background: #F5F5F5!important;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15)!important;
    border-radius: 5px!important;
    padding: 38px 20px!important;
}

.c-ctrl_edit-photo .device_pos_photo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.c-ctrl_edit-photo .device_pos_photo {
    display: flex;
    margin-bottom: 20px;
}

.device_pos_photo img {
    width: 24px;
    height: 24px;
}

.device_pos_photo input {
    display: none;
}

.all_pos_photo {
    display: flex;
    padding: 8px;
    border-radius: 8px;
    width: calc(100% - 80px);
    align-content: space-between;
    column-gap: 16px;
}

.all_pos_photo div {
    display: flex;
    flex: auto;
    /* width: inherit; */
    justify-content: space-between;
}

.all_pos_photo span {
    font-size: 18px;
}

.device_pos_photo input+label {
    display: block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

label.vp_left {
    background-image: url(img/icons/pos-photo-left.svg);
}
label.vp_center {
    background-image: url(img/icons/pos-photo-v-center.svg);
}
label.vp_right {
    background-image: url(img/icons/pos-photo-right.svg);
}

.device_pos_photo input:checked+label.vp_left {
    background-image: url(img/icons/pos-photo-left_a.svg);
}
.device_pos_photo input:checked+label.vp_center {
    background-image: url(img/icons/pos-photo-v-center_a.svg);
}
.device_pos_photo input:checked+label.vp_right {
    background-image: url(img/icons/pos-photo-right_a.svg);
}

label.hp_top {
    background-image: url(img/icons/pos-photo-top.svg);
}
label.hp_center {
    background-image: url(img/icons/pos-photo-h-center.svg);
}
label.hp_bottom {
    background-image: url(img/icons/pos-photo-bottom.svg);
}

.device_pos_photo input:checked+label.hp_top {
    background-image: url(img/icons/pos-photo-top_a.svg);
}
.device_pos_photo input:checked+label.hp_center {
    background-image: url(img/icons/pos-photo-h-center_a.svg);
}
.device_pos_photo input:checked+label.hp_bottom {
    background-image: url(img/icons/pos-photo-bottom_a.svg);
}


.img_fit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 8px;
}

.img_fit p {
    display: block;
}
.img_fit input {
    display: none;
}
.img_fit input+label {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}
.img_fit label.img_fit_d {
    background-image: url(img/icons/img_fit_d.svg);
}
.img_fit label.img_fit_m {
    background-image: url(img/icons/img_fit_m.svg);
}

.img_fit input:checked+label.img_fit_d {
    background-image: url(img/icons/img_fit_d_a.svg);
}
.img_fit input:checked+label.img_fit_m {
    background-image: url(img/icons/img_fit_m_a.svg);
}


.desktop .d-pos_0-0 {
    object-position: left top!important;
}
.desktop .d-pos_0-50 {
    object-position: left center!important;
}
.desktop .d-pos_0-100 {
    object-position: left bottom!important;
}
.desktop .d-pos_50-0 {
    object-position: center top!important;
}
.desktop .d-pos_50-50 {
    object-position: center center!important;
}
.desktop .d-pos_50-100 {
    object-position: center bottom!important;
}
.desktop .d-pos_100-0 {
    object-position: right top!important;
}
.desktop .d-pos_100-50 {
    object-position: right center!important;
}
.desktop .d-pos_100-100 {
    object-position: right bottom!important;
}
.desktop .d-fit_contain {
    object-fit: contain!important;
}
.desktop .d-fit_cover {
    object-fit: cover!important;
}

.mobile .m-pos_0-0 {
    object-position: left top!important;
}
.mobile .m-pos_0-50 {
    object-position: left center!important;
}
.mobile .m-pos_0-100 {
    object-position: left bottom!important;
}
.mobile .m-pos_50-0 {
    object-position: center top!important;
}
.mobile .m-pos_50-50 {
    object-position: center center!important;
}
.mobile .m-pos_50-100 {
    object-position: center bottom!important;
}
.mobile .m-pos_100-0 {
    object-position: right top!important;
}
.mobile .m-pos_100-50 {
    object-position: right center!important;
}
.mobile .m-pos_100-100 {
    object-position: right bottom!important;
}
.mobile .m-fit_contain {
    object-fit: contain!important;
}
.mobile .m-fit_cover {
    object-fit: cover!important;
}

.c-ctrl-sf_description .c-ctrl-sf_item-wrap textarea {
    height: 95px;
}

.c-ctrl-sf_item-wrap input[type=text],
.c-ctrl-sf_item-wrap input[type=number] {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 12px 24px;
}

.c-ctrl-sf_format ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.c-ctrl-sf_format ul li {
    margin-right: 10px;
    display: block;
    margin-bottom: 16px;
    padding-inline-start: 0;
}

.c-ctrl-sf_format input {
    display: none;
}

.c-ctrl-sf_format label {
    display: block;
    width: 88px;
    height: 56px;
    border: none;
    border-radius: 8px;
    background: #D9D9D9;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.c-ctrl-sf_format label:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.16);
}

.c-ctrl-sf_format .c-ctrl-sf_format_row+label {
    background-image: url(img/icons/c-ctrl-sf_format_1.svg) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_column+label {
    background-image: url(img/icons/c-ctrl-sf_format_3.svg) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_row_rev+label {
    background-image: url(img/icons/c-ctrl-sf_format_2.svg) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_blank+label {
    background-image: url(img/icons/c-ctrl-sf_format_4.svg) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_bg_r+label {
    background-image: url(img/icons/c-ctrl-sf_format_5.png) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_bg_l+label {
    background-image: url(img/icons/c-ctrl-sf_format_6.png) ;
}

.c-ctrl-sf_format .c-ctrl-sf_format_bg_c+label {
    background-image: url(img/icons/c-ctrl-sf_format_7.png) ;
}

.c-ctrl-sf_media {
    display: none;
}

.c-ctrl-sf_media+label {
    width: 100%;
    min-height: 110px;
    max-height: 150px;
    padding: 24px;
    border:none;
    border-radius: 8px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
}

.c-ctrl-sf_media+label:hover {
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.16);
}

.c-ctrl-sf_media+label.add_image_filler {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: dashed;
}

.add_image_filler svg {
    margin-bottom: 8px;
    height: 24px;
    width: 24px;
}

.c-ctrl-section_header_with_reset {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.c-ctrl-sf_bonus .c-ctrl-sf_item-wrap+.c-ctrl-sf_item-wrap {
    margin-top: 16px;
}

.c-ctrl-sf_bonus p {
    margin-left: 8px;
}

.c-ctrl-sf_bonus-img {
    display: none;
}

.c-ctrl-sf_bonus-img+label {
    width: 48px;
    min-width: 48px;
    height: 48px;
    padding: 12px;
    margin-right: 8px;
    border: none;
    border-radius: 8px;
    background: #F5F5F5;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.c-ctrl-sf_bonus-img+label img {
    width: 24px;
    height: 24px;
}

.c-ctrl-sf_bonus-img+label.add_media_filler {
    border-width: 1px;
    border-style: dashed;
}

.benefit_item_wrapper {
    position: relative;
}

.benefits_type_openner_wrap {
    width: 100%;
}
.benefits_type_openner {
    width: 100%;
    border: 1px solid var(--color-main-gray);
    border-radius: 8px;
    padding: 14px 24px;
    margin-top: 16px;
}
input:checked+.benefits_type_openner {
    border-radius: 8px 8px 0 0;
}
.benefits_selector_placeholder {
    color: var(--color-main-dark-gray);
    font-size: 16px;
}
.benefits_selector_item {
    width: 100%;
    display: flex;
    align-items: center;
}
.benefits_selector_item img {
    width: 24px;
    height: 24px;
}
.benefits_select {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FBFBFB;
    border: 1px solid var(--color-main-gray);
    border-top: none;
    border-radius: 0 0 8px 8px;
}

.benefits_select label {
    width: 100%;
    gap: 8px;
    padding: 12px 0;
}
.benefits_select label img {
    width: 24px;
    height: 24px;
}

.benefits_select label input+div{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
}

.benefits_select label input:checked+div{
    padding: 12px 24px;
    background: var(--color-main-light-gray);
}

.benefits_hr {
    display: block;
    margin: 0;
    width: calc(100% - 24px - 24px);
    height: 1px;
    background: var(--color-main-light-gray);
    color: var(--color-main-light-gray);
    border: 1px solid var(--color-main-light-gray);
}
.benefit_inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    width: 100%;
}
.benefit_inputs input {
    width: 100%;
}

.edit_benefit {
    display: block;
    width: 18px;
    height: 18px;
    color:transparent;
    /* margin-right: 8px; */
    border: 1px solid hsla(0, 0%, 100%, 0);
    background: hsla(0, 0%, 100%, 0);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url(img/icons/edit_benefit.svg);
    position: absolute;
    right: 52px;
    top: 20px;
    opacity: 0;
}

.delete_benefit {
    display: block;
    width: 18px;
    height: 18px;
    color:transparent;
    /* margin-right: 8px; */
    border: 1px solid hsla(0, 0%, 100%, 0);
    background: hsla(0, 0%, 100%, 0);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url(img/icons/delete.svg);
    position: absolute;
    right: 24px;
    top: 20px;
    opacity: 0;
}

.benefit_item_wrapper:hover .edit_benefit,
.benefit_item_wrapper:hover .delete_benefit {
    opacity: 1;
}


/* =========================== */

.social_net_item_wrapper {
    position: relative;
}
.social_net_type_openner_wrap {
    width: 100%;
}
.social_net_type_openner {
    width: 100%;
    border: 1px solid var(--color-main-gray);
    border-radius: 8px;
    padding: 14px 24px;
    margin-top: 16px;
}
input:checked+.social_net_type_openner {
    border-radius: 8px 8px 0 0;
}
.social_net_selector_placeholder {
    color: var(--color-main-dark-gray);
    font-size: 16px;
}
.social_net_selector_item {
    width: 100%;
    display: flex;
    align-items: center;
}
.social_net_selector_item img {
    width: 24px;
    height: 24px;
}
.social_net_select {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FBFBFB;
    border: 1px solid var(--color-main-gray);
    border-top: none;
    border-radius: 0 0 8px 8px;
}

.social_net_select label {
    width: 100%;
    gap: 8px;
    padding: 12px 0;
}
.social_net_select label img {
    width: 24px;
    height: 24px;
}

.social_net_select label input+div{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
}

.social_net_select label input:checked+div{
    padding: 12px 24px;
    background: var(--color-main-light-gray);
}

.social_net_hr {
    display: block;
    margin: 0;
    width: calc(100% - 24px - 24px);
    height: 1px;
    background: var(--color-main-light-gray);
    color: var(--color-main-light-gray);
    border: 1px solid var(--color-main-light-gray);
}
.social_net_inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 12px;
    width: 100%;
}
.social_net_inputs input {
    width: 100%;
}
.social_network_input {
    border: none;
    margin-left: 8px;
    width: 88%;
}

.social_net_select p {
    margin-left: 16px;
}

.delete_social_net {
    display: block;
    width: 18px;
    height: 18px;
    color:transparent;
    /* margin-right: 8px; */
    border: 1px solid hsla(0, 0%, 100%, 0);
    background: hsla(0, 0%, 100%, 0);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url(img/icons/delete.svg);
    position: absolute;
    right: 12px;
    top: 20px;
}

.add_social_net {
    margin-top: 24px;
}

/* ========================= */

.c-ctrl-sa_format ul {
    display: flex;
    padding: 0;
    margin: 0;
    /* justify-content: space-around; */
}

.c-ctrl-sa_format ul li {
    display: block;
    width: 83px;
    /* text-align: center; */
}

.c-ctrl-sa_format ul li input {
    display: none;
}

.c-ctrl-sa_format ul li input+label {
    display: block;
    font-weight: 300;
    font-size: 14px;
    color: #66757F;
}

.c-ctrl-sa_format ul li input:checked+label,
.c-ctrl-sa_format ul li label:hover {
    font-weight: 700;
    color: #324AC7;
}

.c-ctrl-sa .c-ctrl-sa_item-wrap {
    display: flex;
    align-items: center;
}

.c-ctrl-sa .c-ctrl-sa_question .c-ctrl-sa_item-wrap {
    position: relative;
}

.c-ctrl-sa_item-wrap+.c-ctrl-sa_item-wrap {
    margin-top: 16px;
}

.c-ctrl-sa_item-wrap input[type=text],
.c-ctrl-sa_item-wrap input[type=number],
.c-ctrl-sa_item-wrap input[type=date],
.c-ctrl-sa_item-wrap input[type=time],
.c-ctrl-sa_item-wrap select {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
}

.c-ctrl-sa_item-wrap textarea {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    padding: 12px 16px;
}

/* .c-ctrl-sa_item-wrap input[type=text]::placeholder,
.c-ctrl-sa_item-wrap textarea::placeholder {
    color: #66757F;
} */

.c-ctrl-sa_a-list .handle_answer {
    width: 24px;
    height: 48px;
    border-radius: 4px;
}

.c-ctrl-sa_a-list .c-ctrl-sa_item-wrap input[type=text] {
    min-width: calc(100% - 90px);
    max-width: calc(100% - 32px);
    padding: 12px 32px 12px 16px;
}
.variant .c-ctrl-sa_a-list .c-ctrl-sa_item-wrap input[type=text] {
    width: calc(100% - 90px);
}

.list .c-ctrl-sa_a-list .c-ctrl-sa_item-wrap input[type=text] {
    width: calc(100% - 32px);
}

.c-ctrl-sa_question-img {
    display: none;
}

.c-ctrl-sa_question-img_wrap {
    width: 60px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}

.c-ctrl-sa_question-img_preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(56,56,56,.7);
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
}

.c-ctrl-sa_question-img_preloader>span {
    display: block;
    box-shadow: none!important;
    width: 24px;
    height: 24px;
}

.c-ctrl-sa_question-img_preloader>span img {
    width: 100%;
    height: 100%;
    box-shadow: none!important;
}

.c-ctrl-sa_question-img+label {
    display: block;
    width: 100%;
    height: 100%;
    background: #F5F5F5;
    background-image: url(img/icons/c-ctrl_add-media.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
}

.c-ctrl-sa_question-img+label.c-ctrl-sa_question-img_filler {
    background-size: 24px;
}

.c-ctrl-sa_answers ul {
    padding: 0;
    margin: 0;
}

.c-ctrl-sa_answers ul li {
    padding-inline-start: 0;
    padding: 0;
    margin: 0;
    margin-bottom: 16px;
    position: relative;
    overflow: hidden;
}

.c-ctrl-sa_answer-img {
    display: none;
}

.c-ctrl-sa_answer-img_wrap {
    width: 0px;
    margin-right: 8px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
}
.list .c-ctrl-sa_answer-img_wrap {
    margin-right: 0px;
}
.c-ctrl-sa_answer-img+.c-ctrl-sa_answer-img_wrap {
    min-width: 48px;
    width: 48px;
    height: 48px;
}

.c-ctrl-sa_answer-img_preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(56,56,56,.7);
    -webkit-backdrop-filter: blur(1.5px);
    backdrop-filter: blur(1.5px);
}

.c-ctrl-sa_answer-img_preloader>span {
    display: block;
    box-shadow: none!important;
    width: 24px;
    height: 24px;
}

.c-ctrl-sa_answer-img_preloader>span img {
    width: 100%;
    height: 100%;
    box-shadow: none!important;
}

.c-ctrl-sa_answer-img+.c-ctrl-sa_answer-img_wrap>label {
    display: block;
    min-width: 48px;
    width: 48px;
    height: 48px;
    z-index: 1;
    margin-right: 8px;
    border-radius: 8px;
    background: #F5F5F5;
    background-image: url(img/icons/c-ctrl_add-media.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.answer-img_filler {
    border-width: 1px;
    border-style: dashed;
    background-size: 24px!important;
}

.c-ctrl-sa_add-answer.variant {
    transition-duration: 0.05s;
}

.c-ctrl-sa_add-answer.list {
    margin-left: 0px;
    transition-duration: 0.05s;
}

.c-ctrl-sa_item-wrap label+p {
    margin-left: 23px;
    color: #66757F;
    font-family: 'Manrope', sans-serif;
    font-weight: 300;
}

.c-ctrl-sa_consult_wrap {
    margin-top: 16px;
}

.c-ctrl-sa_consult_wrap span {
    display: block;
    color: #9FA8AF;
    font-size: 14px;
    font-weight: 300;
    font-style: italic;
    margin-bottom: 10px;
}

.c-ctrl-sa_consult-img {
    display: none;
}

.c-ctrl-sa_consult-img+label {
    width: 60px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    background: #F5F5F5;
    background-image: url(img/icons/c-ctrl_add-media.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.c-ctrl-sa_item-wrap.c-ctrl-sa_item-wrap__switcher {
    display: flex;
}

.c-ctrl-sa_range_min-max,
.c-ctrl-sa_range_start_end,
.c-ctrl-sa_datetime_min-max_date,
.c-ctrl-sa_datetime_min-max_time {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.vue-slider-ltr .vue-slider-mark,
.vue-slider-rtl .vue-slider-mark {
    -webkit-transform: translate(-50%, 20px) !important;
    transform: translate(-50%, 20px) !important;
    height: 0 !important;
}


/* ========================== */

.c-ctrl-sa_item-wrap_quill-editor {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ql-toolbar {
    width: 100%;
}

.ql-toolbar+.ql-container {
    width: 100%;
    height: auto;
    min-height: 120px;
}



/* ========================== */

.c-ctrl-sform .c-ctrl-sform_item-wrap {
    display: flex;
}

.c-ctrl-sform_item-wrap+.c-ctrl-sform_item-wrap {
    margin-top: 16px;
}

.c-ctrl-sform_item-wrap input[type=text],
.c-ctrl-sform_item-wrap input[type=tel],
.c-ctrl-sform_item-wrap input[type=email] {
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
}

.c-ctrl-sform_item-wrap textarea {
    width: 100%;
    height: 68px;
    border-radius: 8px;
    padding: 12px 16px;
}

.c-ctrl-sform_item-wrap input[type=text]::placeholder,
.c-ctrl-sform_item-wrap input[type=phone]::placeholder,
.c-ctrl-sform_item-wrap input[type=email]::placeholder,
.c-ctrl-sform_item-wrap textarea::placeholder {
    color: #66757F;
}

.c-ctrl-sform_img {
    display: none;
}

.c-ctrl-sform_img+label {
    width: 60px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    background: #F5F5F5;
    background-image: url(img/icons/c-ctrl_add-media.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.c-ctrl-sform_item-wrap__switcher {
    display: flex;
    flex-direction: column;
}

.c-ctrl-sform_item_switchers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
}

.contact-required_wrap,
.contact-availability_wrap,
.contact-international_wrap,
.c-ctrl-sform_item-wrap_privacy_policy,
.c-ctrl-sform_item-wrap_thank-you,
.c-ctrl-sform_item-wrap_redirect {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contact-required_wrap label+label,
.contact-availability_wrap label+label,
.contact-international_wrap label+label,
.c-ctrl-sform_item-wrap_privacy_policy label+label,
.c-ctrl-sform_item-wrap_thank-you label+label,
.c-ctrl-sform_item-wrap_redirect label+label {
    margin-left: 4px;
}
.contact-international_wrap {
    margin-bottom: 16px;
}

.c-ctrl-sform_item-wrap label.switch+input {
    margin-left: 23px;
    text-align: center;
}

label.radiobutton {
    width: 24px;
    height: 24px;
    padding: 2px;
}

label.radiobutton input {
    display: none;
}

label.radiobutton span {
    position: relative;
    display: block;
    border-radius: 24px;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 1px solid #1C1C1C;
}

label.radiobutton input+span::before {
    display: block;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 12px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: transparent;
    transition: all ease 0.2s;
}

label.radiobutton input:checked+span {
    border: 1px solid #3749C0;
}

label.radiobutton input:checked+span::before {
    background: #3749C0;
    transition: all ease 0.2s;
}

.c-ctrl-sform_contact_input_wrap input[type=text] {
    margin-bottom: 16px;
}
.input_phone_settings_container {
    margin-bottom: 16px;
}

.c-ctrl-sform_contact_input_wrap input[type=text]#phone {
    margin-bottom: 0;
}

.c-ctrl-sform_item-wrap_url p {
    display: block;
    width: 70px;
}

.c-ctrl-sform_item-wrap.c-ctrl-sform_item-wrap_btn-value {
    flex-direction: column;
    align-self: start;
    align-items: start;
}

.c-ctrl-sform_item-wrap_btn-value p {
    color: #66757F;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 16px;
}

.slide_form_checkboxes {
    width: 100%;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    margin: 16px 10px 0 10px;
}

.slide_form_checkbox_text {
    display: flex;
    flex-direction: row;
}

.slide_form_checkbox_text input {
    display: none;
}

.slide_form_checkbox_text .checkbox_mark {
    position: relative;
    display: block;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    /* border: 1px solid #000; */
    border: none;
    background-color: #FFF;
    border-radius: 3px;
    margin-right: 8px;
}

.slide_form_checkbox_text:hover input ~ .checkbox_mark {
    position: relative;
    background-color: var(--color-main-light-blue);
}
.checkbox_mark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Показываем когда отмечен */
.slide_form_checkbox_text input:checked ~ .checkbox_mark:after {
  display: block;
}
/* Стили индикатора */
.slide_form_checkbox_text .checkbox_mark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 8px;
  border-radius: 1px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.rtl .slide_form_checkbox_text .checkbox_mark {
    margin-right: 0;
    margin-left: 8px;
}
/* .mobile .slide_form_checkboxes {
    display: flex;
    flex-direction: column;
} */

/* ========================= */

.c-ctrl-sl_item-wrap {
    display: flex;
    flex-direction: column;
}

.c-ctrl-sl_item-wrap+.c-ctrl-sl_item-wrap {
    margin-top: 16px;
}

.c-ctrl-sl_item-wrap p {
    width: 110px;
    color: #66757F;
    font-weight: 300;
}

.c-ctrl-sl_item-wrap_big p {
    width: 70px;
}

.c-ctrl-sl_item-wrap.c-ctrl-sl_item-wrap_img p {
    width: 100%;
    margin-bottom: 16px;
}

.c-ctrl-sl_item-wrap.c-ctrl-sl_item-wrap_url p {
    width: 70px;
}

.c-ctrl-sl_item-wrap.c-ctrl-sl_item-wrap_finish-btn {
    flex-wrap: wrap;
}

.c-ctrl-sl_item-wrap.c-ctrl-sl_item-wrap_finish-btn p {
    width: 100%;
    margin-bottom: 16px;
}

.c-ctrl-sl_item-wrap_img {
    flex-direction: column;
    align-items: start;
}

.finish-img {
    display: none;
}

.finish-img+label {
    width: 60px;
    height: 40px;
    margin-right: 10px;
    border-radius: 8px;
    background: #F5F5F5;
    background-image: url(img/icons/c-ctrl_add-media.png);
    background-position: center;
    background-size: 80%;
    background-repeat: no-repeat;
}

.c-ctrl-sl_item-subwrap {
    display: flex;
    align-items: center;
}

.c-ctrl-sl_item-wrap textarea {
    width: 100%;
    height: 80px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    padding: 12px 16px;
}

.c-ctrl-sl_item-wrap input[type=text],
.c-ctrl-sl_item-wrap input[type=number] {
    height: 48px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    width: 100%;
    padding: 12px 16px;
}

.c-ctrl-sl_item-wrap input[type=text]::placeholder,
.c-ctrl-sl_item-wrap textarea::placeholder,
.c-ctrl-sl_item-wrap input[type=number]::placeholder {
    color: #66757F;
}





/* ======================== */

.rtl textarea,
.rtl input[type=text],
.rtl input[type=email] {
    text-align: right;
}

.rtl .constructor-body_display-header .pagination-container {
    flex-direction: row-reverse;
}

.rtl .pagination-container .paginate-buttons.back-button,
.rtl .pagination-container .paginate-buttons.next-button {
    transform: rotate(180deg);
}


/* ======================== */

.c-ctrl-d_footer {
    display: flex;
    justify-content: space-evenly;
}

.c-ctrl-d_footer input {
    font-size: 14px;
    padding: 11px 25px;
}

.c-ctrl-d_footer input.btn-default-white {
    border: 1px solid #dadada;
}

.constructor_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: calc(100% - 356px);
    border-radius: 10px;
    padding: 24px 28px 20px 24px;
    margin: 0 auto;
    position: relative;
    z-index: 100;
}

.constructor_body_settings {
    justify-content: flex-start;
}

.constructor_body_settings_content-wrap {
    width: 100%;
    height: 100%;
}

.constructor_settings_integrations {
    height: calc(100% - 48px);
}

.constructor-body_display-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    margin-bottom: auto;
}

.constructor-body_display-header_nav {
    display: flex;
    align-items: center;
}

.constructor-body_display-header .pagination-container {
    border-radius: 8px;
    padding: 4px 0;
    align-items: center;
    height: 40px;
    margin: 0;
}

.pagination-container .paginate-buttons {
  border: none;
  display: flex;
  border-radius: 8px;
  padding: 6px 16px;
}

.pagination-container .paginate-buttons.back-button,
.pagination-container .paginate-buttons.next-button {
    padding: 7px;
    margin: 0 4px;
}

.constructor-body_display-header_action {
    display: flex;
    column-gap: 16px;
}

.integration_display_wrap,
.aditional_settings_wrap {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: auto;
    padding: 24px;
    border-radius: 8px;
}

.integration_display_wrap>p+p {
    margin-top: 8px;
}

.integration_display_head,
.setting-point_display_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
}

.integration_display_input_wrap,
.setting_display_input_wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    padding: 0 !important;
    margin-bottom: 16px;
    margin-top: 16px;
    width: 100%;
}
.setting_display_input_wrap select {
    width: 100%;
    max-width: 468px;
}
.integration_display_tg .integration_display_input_wrap .telegram_token_label {
    padding: 12px 42px 12px 16px;
    border-radius: 8px;
    border: var(--border-default);
    background: var(--color-main-white);
}

.integration_display_tg .integration_display_input_wrap .telegram_token_label p {
    width: 100%;
}

.telegram_token_checkbox {
    display: none;
}

.integration_display_input_wrap p {
    margin-bottom: 0;
}

.integration_tg_copy_token {
    position: absolute;
    right: 12px;
    bottom: 10px;
    display: block;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
    background-color: transparent;
    background-image: url(img/icons/copy-to-clipboard.svg);
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
}

.detail_instruction_wrap {
    display: flex;
    align-items: baseline;
    gap: 8px;
}

.detail_instruction_wrap a {
    padding: 4px 8px 4px 28px;
    border-radius: 8px;
    background-image: url(img/icons/detail_instruction.svg);
    background-position: 4px center;
    background-size: 16px;
    background-repeat: no-repeat;
}
.detail_instruction_wrap label {
    padding: 4px 8px 4px 28px;
    border-radius: 8px;
    background-image: url(img/icons/detail_instruction.svg);
    background-position: 4px center;
    background-size: 16px;
    background-repeat: no-repeat;
}
.detail_instruction_wrap label input {
    display: none;
}

.integration_about_text {
    width: 100%;
    max-width: 480px;
}

.integration_display_input_wrap {
    width: 100%;
    max-width: 480px;
    border-bottom: none!important;
}

.integration_display_buttons_wrap {
    display: flex;
    gap: 16px;
}

.integration_display_buttons_wrap input {
    width: auto;
}

.constructor-body_slide {
    overflow: hidden;
    height: calc(100% - 65px);
    width: 100%;
    min-width: 900px;
    min-height: 650px;
    /* 
    height: 552px;
    max-height: calc(100% - 44px);
    width: 100%;
    max-width: 900px;
    height: calc(100vw * 0.5);
    width: 100%;
    background: #ececec;
    border: 1px solid rgba(217, 217, 217, 1); 
    */
    box-shadow: 4px 4px 20px 5px rgba(0, 0, 0, 0.2);
    position: relative;
}

.constructor-body_slide > div {
    height: 100%;
    width: 100%;
}

.constructor-body_slide * {
    font-family: 'Manrope', 'Montserrat', sans-serif;
}

.constructor_body .wrap_scale {
    height: calc(100% - 96px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 0;
}

.wrap_scale .constructor-body_slide {
    /* transform-origin: top; */
    transform: scale(0);
    transition-duration: 0.1s;
}

/* ====================== */

.c-ctrl-s_custom_theme-wrap {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 16px 0;
}
.c-ctrl-s_custom_theme_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom_theme_points {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
}

.custom_theme_points label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.custom_theme_point_right_side {
    width: 40px;
    display: flex;
    justify-content: space-between;
}

.custom_theme_point_right_side>span {
    display: block;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.custom_theme_point_right_side>input {
    display: none;
}
.c-ctrl-s_custom_theme-wrap .color-preview {
    border: 1px solid var(--color-main-gray);
    border-radius: 4px;
}
.c-ctrl-s_custom_theme-wrap .custom_theme_change-button {
    background-image: url(img/icons/edit_theme_color.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker {
    width: 100%!important;
    box-shadow: none!important;
    -user-select: none!important;
    background-color: inherit!important;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container>div {
    background-color: inherit!important;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container .vc-saturation {
    border-radius: 5px;
    overflow: hidden;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container>div>div:last-child {
    background-color: inherit!important;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container>div>div:last-child>div:first-child>div:first-child {
    display: none;
}
.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container>div>div:last-child>div:first-child>div:last-child {
    margin: 0!important;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker--container .vc-current-color {
    border-radius: 8px;
    overflow: hidden;
    width: 28px!important;
}

.c-ctrl-s_custom_theme-wrap .vc-colorpicker .vc-hue-slider__bar {
    background: -ms-linear-gradient(left, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red 100%) !important;
    background: -webkit-linear-gradient(left, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red 100%) !important;
    background: linear-gradient(to right, red 0, #ff0 16.66%, #0f0 33.33%, #0ff 50%, #00f 66.66%, #f0f 83.33%, red 100%) !important;
}

.custom_theme_mode_wrap {
    display: flex;
    width: 100%;
    background-color: var(--color-main-light-gray);
    border-radius: 8px;
    margin-top: 16px;
}

.custom_theme_mode_wrap>label {
    width: 50%;
    border: 1px solid transparent;
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-main-black);
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom_theme_mode_wrap>input:checked+label {
    color: var(--color-main-blue);
    background-color: var(--color-main-white);
    border-color: var(--color-main-blue);
}


/* ========================= */

.constructor_vidget_body header>.container>div {
    width: auto;
}

.constructor_vidget_body {
    height: 100%;
    width: calc(100% - 370px);
    max-width: 100%;
    flex-direction: column;
    overflow: visible;
    border-radius: 10px;
}

.constructor_vidget_body.constructor_body .wrap_scale {
    height: 100%;
    overflow: visible;
}

.constructor_vidget_body .constructor-body_slide {
    height: 100%;
    min-height: 725px;
    max-height: 100%;
    width: 100%;
    min-width: 1050px;
    max-width: 100%;
    box-shadow: none;
}

.constructor_vidget_body section,
.constructor_vidget_body header {
    width: 100%;
    margin-bottom: 40px;
}

.constructor_vidget_body header  {
    background: gainsboro;
}

.constructor_vidget_body .container {
    width: 100%;
    max-width: 100%;
    padding: 0 40px;
}

.constructor_vidget_body .container,
.constructor_vidget_body .container .row,
.constructor_vidget_body nav,
.constructor_vidget_body .nav-drop,
.constructor_vidget_body .nav-drop ul,
.constructor_vidget_body .drop-holder,
.constructor_vidget_body .drop-holder .social-networks {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.constructor_vidget_body .logo {
    width: 150px;
}

.constructor_vidget_body nav {
    display: flex;
}

.constructor_vidget_body nav ul,
.constructor_vidget_body .drop-holder ul {
    display: flex;
    align-items: center;
    list-style: none;
}
.constructor_vidget_body nav ul a {
    color: #313131;
    font-size: 14px;
    padding: 0 10px;
    font-weight: 700;
}

.constructor_vidget_body .drop-holder ul li{
    width: 25px;
    height: 25px;
    margin: 0 5px;
}

.constructor_vidget_body .drop-holder ul li img {
    width: 100%;
    height: 100%;
}

.constructor_vidget_body .visual .text-block {
    width: 220px;
}

.constructor_vidget_body .heading-holder {
    margin-bottom: 20px;
}

.constructor_vidget_body .visual .block_for_quiz_integrated {
    width: calc(100% - 260px);
}

#adsquiz_vidget_wrap {
  display:none;
  opacity:0;
  position: fixed;
  z-index:999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background:rgba(0,0,0,0.3);
  transition: opacity 0.3s;
}

#adsquiz_vidget_wrap.open {
  display: flex;
}

#adsquiz_vidget_wrap.fade-in {
  opacity: 1;
}
#adsquiz_overlay {
  display:block;
  width:100%;
  height:100%;
  z-index:1;
  position:absolute;
}
#adsquiz_popup {
    z-index:2;
    position:relative;
    max-width:920px;
    height: 520px;
    width: 100%;
}
.adsquiz_close_cross {
  position:absolute;
  z-index:9;
  top:24px;
  right:20px;
  font-size:19px;
  height:19px;
  width:19px;
  display:block;
  cursor:pointer;
}
.adsquiz_scrollbar-padding {
  padding-right: 16px;
}

.constructor_vidget_body .social-placeholder {
    margin-top: 20px;
    width: 100%;
}

.constructor_vidget_body .social-placeholder img {
    object-fit: contain;
    height: 100%;
}

.constructor_vidget_body header,
.constructor_vidget_body .text-block,
.constructor_vidget_body .main {
    /* background: rgba(255, 255, 255, 0.7); */
    filter: blur(6px);
}






/* ======================= */

.quiz-body_hidden_blur {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999999;
}

.hidden_step_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 60px;
    background: #FFF;
    border-radius: 10px;
    max-width: 80%;
}

.mobile .hidden_step_modal {
    padding: 32px 60px;
}

.hidden_step_modal svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.hidden_step_modal p {
    text-align: center;
}
.quiz-body_blur {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    /* background: rgba(0, 0, 0, 0.3); */
    z-index: 1;
}

.step-first-slide_wrap {
    display: flex;
    height: 100%;
    position: relative;
    z-index: 10;
    overflow: visible;
}

.step-first-slide .block_info {
    display: flex;
    flex-direction: column;
    z-index: 100;
}

.step-first-slide .block_info h1 {
    font-size: 46px;
    line-height: 54px;
    text-transform: none;
    font-weight: 700;
    white-space: pre-wrap;
}

.step-first-slide .block_info_body>p {
    font-size: 16px;
    font-weight: 300;
    white-space: pre-wrap;
}

.step-first-slide .bonus-and-start {
    display: flex;
    min-width: 250px;
    max-width: 552px;
}

.step-first-slide .bonus-block {
    position: relative;
    display: flex;
    align-items: center;
    /* width: 250px; */
    padding: 12px 24px;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
}

.step-first-slide .bonus-block img {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    object-fit: contain;
    object-position: center;
}

.step-first-slide .bonus-block svg {
    width: 32px;
    height: 32px;
    margin-right: 20px;
    object-fit: contain;
    object-position: center;
}

.step-first-slide .bonus-block p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    display: flex;
    align-items: center;
    /* word-wrap: break-word;
    overflow-wrap: break-word; */
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    width: calc(100% - 44px);
    height: 40px;
}

.step-first-slide .start {
    display: block;
    text-align: center;
    min-width: 260px;
    max-width: 100%;
    width: fit-content;
    padding: 20px 24px;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    background: #324ac7;
    border: 1px solid #324ac7;
    border-radius: 10px;
    font-weight: 700;
    margin-bottom: 16px;
}

.step-first-slide .footer-links {
    /* margin-top: auto; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* margin-top: auto; */
}

.step-first-slide .footer-links a {
    padding: 0 25px 0 0;
    color: #ccc;
    font-size: 10px;
}

.step-first-slide .footer-links a span {
    color: #324AC7;
    font-weight: 700;
    font-size: 10px;
}


.step-first-slide.label_false .footer-links a {
    display: none;
}

.terms-and-policy {
    opacity: .6;
    font-size: 10px;
    direction: rtl;
}

.step-first-slide .footer-links .terms-and-policy a {
    display: contents;
}

.integration_active__switcher {
    display: flex;
    gap: 16px;
}
.integration_point__switcher {
    display: flex;
    gap: 16px;
}
.integration_switcher_label {
    color: #66757F;
    font-family: 'Manrope', sans-serif;
    font-weight: 300;
}


/* =========================== */


.benefits_questions_wrap {
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
}
.benefits_wrap_label {
    z-index: 110;
}

.benefits_wrap_label_contact_mobile .quiz_slides_benefits {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
    justify-content: space-between;
    /* padding-right: 16px; */
    max-width: 564px;
}

.benefits_wrap_label_contact_mobile .quiz_slides_benefits .benefit_wrap {
    width: calc(50% - 4px);
}



.benefits_wrap_label_contact_mobile .quiz_slides_benefits .benefit_wrap p {
    width: auto;
    min-width: auto;
}

.quiz_slides_benefits {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;

}

.benefits_full_opener {
    display: none;
}

.benefit_wrap {
    display: flex;
    position: relative;
    align-items: center;
    width: 64px;
    max-width: 64px;
    padding: 8px 16px;
    border-radius: 64px;
    margin-top: 0px;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    transition-delay: 0s, 0.03s;
    transition-property: all, border-radius;
    z-index: 100;
}

.benefit_wrap::after,
.step-first-slide .bonus-block::after {
    content: url(img/icons/lock_benefit.svg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    padding: 6px;
    border-radius: 20px;
    background-color: #F3F4F9;
    position: absolute;
    top: -10px;
    right: -10px;
    opacity: 0;
}
.step-first-slide .bonus-block::after {
    opacity: 1;
}

.benefit_wrap:nth-of-type(2) {
    opacity: 0;
    padding: 0 16px;
    z-index: 90;
}

.benefit_wrap:nth-of-type(2) img {
    opacity: 0;
    padding: 0 24px;
    height: 0;
}

.benefit_wrap:nth-of-type(2) p {
    margin-left: 12px;
    height: 0;
}


.benefit_wrap img {
    width: 32px;
    height: 32px;
}

.benefit_wrap p {
    display: flex;
    align-items: center;
    margin-left: 18px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    width: 190px;
    height: 40px;
    min-width: 190px;
    opacity: 0;
    transition-property: all, opacity;
    transition-duration: 0.3s, 0.1s;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap {
    padding: 12px 18px;
    max-width: 280px;
    width: 280px;
    border-radius: 8px;
    margin-top: 4px;
    overflow: visible;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap::after {
    opacity: 1;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap p {
    margin-left: 12px;
    opacity: 1;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap:nth-of-type(2) {
    opacity: 1;
    padding: 12px 24px;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap:nth-of-type(2) img {
    height: 32px;
    opacity: 1;
    padding: 0;
}

.benefits_full_opener:checked+.quiz_slides_benefits .benefit_wrap:nth-of-type(2) p {
    margin-left: 12px;
    height: 40px;
}



/* ======================== */

/* .step-first-slide-format_row .step-first-slide_wrap {
    
} */

.step-first-slide-format_row .step-first-slide_wrap>div {
    width: 50%;
}

.step-first-slide-format_row .block_img img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
}

.step-first-slide-format_row .block_info {
    padding: 56px 56px 64px 48px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.step-first-slide-format_row .block_info_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 0;
    /* padding: 0 0 95px 0; */
    /* height: calc(100% - 170px); */
}

.step-first-slide-format_row .block_info_body>h1,
.step-first-slide-format_row .block_info_body>p {
    margin-bottom: 14px;
}

/* .step-first-slide-format_row .start {
    margin-top: 40px;
} */

.step-first-slide-format_row .bonus-and-start {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px 8px;
    margin-top: auto;
}

.step-first-slide-format_row .footer-links {
    margin-left: auto;
    margin-right: auto;
}

.step-first-slide-format_row.row_rev  .step-first-slide_wrap {
    flex-direction: row-reverse;
}

/* ========================= */


.step-first-slide-format_column .block_info {
    padding: 20px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.step-first-slide-format_column .block_info_body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100% - 100px);
}

.step-first-slide-format_column .step-first-slide_wrap,
.step-first-slide-format_blank .step-first-slide_wrap {
    flex-direction: column;
    align-items: center;
    padding: 10px 40px;
}

.step-first-slide-format_column .step-first-slide_wrap>div {
    height: 50%;
    max-width: 100%;
}

.step-first-slide-format_column .step-first-slide_wrap>.block_img {
    height: 40%;
    border-radius: 8px;
    overflow: hidden;
}

.step-first-slide-format_column .step-first-slide_wrap>.block_info {
    height: 60%;
}

.step-first-slide-format_column .block_img {
    width: 500px;
    max-width: 100%;
}

.step-first-slide-format_column .block_img img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.step-first-slide-format_column .block_info_body,
.step-first-slide-format_blank .block_info_body {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.step-first-slide-format_column .block_info_body>h1,
.step-first-slide-format_column .block_info_body>p,
.step-first-slide-format_blank .block_info_body>h1,
.step-first-slide-format_blank .block_info_body>p {
    margin-bottom: 14px;
    text-align: center;
}

.step-first-slide-format_column .block_info_body>h1 {
    width: 100%;
    /* max-width: 650px; */
}

.step-first-slide-format_column .block_info_body>p,
.step-first-slide-format_blank .block_info_body>p {
    /* width: 600px; */
    max-width: 100%;
}

.step-first-slide-format_column .bonus-block {
    margin-right: 10px;
}

/* .step-first-slide-format_column .start {
    margin-left: 10px;
} */

.step-first-slide-format_column .bonus-and-start {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    margin-top: 16px;
}

.step-first-slide-format_column .footer-links,
.step-first-slide-format_blank .footer-links {
    align-self: flex-start;
}

/* ========================= */


.step-first-slide-format_blank .step-first-slide_wrap>div {
    max-width: 100%;
}

.step-first-slide-format_blank .step-first-slide_wrap>.block_img {
    height: 0%;
    opacity: 0;
}

.step-first-slide-format_blank .step-first-slide_wrap>.block_info {
    height: 100%;
    align-items: center;
}

.step-first-slide-format_blank .block_info_body {
    padding: 0 25px 10% 25px;
    height: calc(100% - 20px);
}

.step-first-slide-format_blank.mobile .block_info_body {
    padding: 0;
}

.step-first-slide-format_column .block_info_body {
    height: auto;
}

.step-first-slide-format_blank .block_img {
    width: 0px;
    max-width: 0%;
}

.step-first-slide-format_blank .block_img img {
    width: 0%;
    height: 0%;
}

.step-first-slide-format_blank .block_info_body>h1 {
    font-size: 32px;
}

.step-first-slide-format_blank .bonus-and-start {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 500px;
    max-width: 100%;
    gap: 16px 8px;
}

.step-first-slide-format_blank .start {
    /* margin-top: 50px; */
    margin-bottom: 16px;
}


/* ========================= */

.step-first-slide.full_bg .block_img img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 1;
}
.step-first-slide.full_bg .block_img {
    opacity: 1;
}

.step-first-slide.full_bg .block_img .bg_darker {
    opacity: 0.8;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.step-first-slide-format_row.full_bg .block_info {
    border-left: 1px solid rgba(255, 255, 255, 0.3);
}
.step-first-slide-format_row.full_bg .block_img .bg_darker {
    background: linear-gradient(270deg, rgba(0, 0, 0, 0.9) 43.44%, rgba(0, 0, 0, 0.18) 100%);
}

.step-first-slide-format_row.row_rev.full_bg .block_info {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: none;
}
.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.9) 45.89%, rgba(0, 0, 0, 0.18) 100%);
}

.step-first-slide-format_blank.full_bg .block_img .bg_darker {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 49.04%, rgba(0, 0, 0, 0.18) 100%);
}

.step-first-slide.full_bg.mobile .block_img .bg_darker {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 50%, rgba(0, 0, 0, 0.18) 100%);
}

.step-first-slide-format_row.full_bg .block_info,
.step-first-slide-format_row.row_rev.full_bg .block_info {
    border-color: rgba(255, 255, 255, 0.3);
}

.step-first-slide-format_row.full_bg.mobile .block_info,
.step-first-slide-format_row.row_rev.full_bg.mobile .block_info {
    border: none;
}




/* =========================== */

.step-any-slide {
    background: #ececec;
    /* border-radius: 11px; */
}
 
.step-any-slide .ps--active-x>.ps__rail-x {
    display: none;
}
.step-any-slide .ps--active-y>.ps__rail-y {
	display: block;
}

.step-any-slide.format_custom {
    display: flex;
}

.step-any-slide.format_custom>img {
    height: 100%;
    width: 50%;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
        z-index: 10;
}

.step-any-slide.format_custom .any-format-slide_wrap .slide_body {
    width: 100%;
    max-width: 1100px;
}

.step-any-slide.format_custom>img+.any-format-slide_wrap .slide_body {
    width: 100%;
    max-width: 100%;
}

.step-any-slide.format_custom .any-format-slide_wrap .slide_body {
    width: 100%;
    max-width: 660px;
}

.custom.slide_answers.slide_answers_custom {
    width: 100%;
    max-width: 448px;
}



.any-format-slide_wrap {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
    height: 100%;
    width: 100%;
}

.any-format-slide_wrap .block_info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 32px 48px;
    transition: none;
}

.any-format-slide_wrap .block_img {
    width: 50%;
}
.any-format-slide_wrap .block_img img{
    height: 100%;
}
.any-format-slide_wrap .block_img+.block_info {
    width: 50%;
}

.mobile .any-format-slide_wrap .block_img {
    width: 100%;
}
/* .mobile .any-format-slide_wrap .block_img img{
    height: 100%;
} */
.mobile .any-format-slide_wrap .block_img+.block_info {
    width: 100%;
}

/* .slide_header {} */
.mobile .slide_progresbar {
    margin: -16px -12px 0;
}
.mobile .slide_footer .footer-links {
    position: relative;
}
.slide_body {
    width: 100%;
    height: calc(100% - 10px - 98px - 20px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.002s ease;
    gap: 16px 0;
}
.step-any-slide .slide_body {
    height: calc(100% - 56px);
    /* overflow: auto; */
}
.mobile.step-any-slide .slide_body {
    height: calc(100% - 160px);
    /* padding: 0 20px 0 0; */
}
.mobile.step-any-slide .slide_answers {
    padding: 0;
}
.mobile.step-any-slide .slide_text {
    padding: 0 16px 0 0;
}
.mobile.step-any-slide .ps {
    padding: 0 16px 0 0;
}
/* .empty_page .slide_body {
    overflow: auto;
} */

.slide_text {
    text-align: center;
    width: 100%;
    max-width: 1100px;
    margin: 0 auto 16px;
}
.slide_title {
    font-size: 32px;
    line-height: 56px;
    color: #000;
    text-transform: none;
    margin: 20px 0 0;
}
.empty_page .slide_title {
    margin-top: 40px;
}

.mobile .slide_title {
    font-size: 28px;
    line-height: 40px;
}
.slide_description {
    color: #000000;
    font-weight: 300;
    width: 100%;
    max-width: 580px;
    margin: 20px auto 0;
    padding-right: 15px;
    white-space: pre-wrap;
}
.slide_consultant {
    display: flex;
    align-items: center;
    width: 300px;
    padding: 10px;
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 20px;
}

.consult_img {
    width: 40px;
    height: 40px;
    margin-left: 20px;
    object-fit: cover;
    object-position: center;
}
.consult_info {
    text-align: left;
    margin-left: 20px;
}
.consult_name {
    color: #313131;
    margin-bottom: 5px;
    font-weight: 500;
}
.consult_phrase {
    color: #313131;
    font-weight: 300;
}

.slide_answers {
    width: 100%;
    max-width: 1100px;
    margin: 20px auto 0;
    padding: 0 20px;
    transition: all 0.1s ease;
    transition-delay: 0s;
}

.empty_page .slide_answers .ps {
    padding: 0 16px;
}

.answers_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.answers_wrap .ps {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.answer_wrap {
    display: block;
    border-radius: 5px;
    overflow: hidden;
    width: calc(25% - 40px);
    border: 1px #ccc solid;
    margin: 0 20px 10px 20px;
    position: relative;
}

.answer_wrap>div {
    margin: auto 0;
}

/* input[type=radio]+.answer_wrap {
    border-radius: 50px;
} */

.list .answer_wrap>div {
    height: 100%;
}

.step-any-slide.format_variant .answer_wrap img {
    width: 100%;
    height: 190px;
    object-fit: cover;
    /* border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
    border-radius: 0;
}

.answer_wrap>div {
    padding: 16px 24px;
    display: flex;
    align-items: center;
}

.slide_answers_variant .answer_wrap:hover>div {
    border-color: transparent;
}

.slide_answers_variant .answer_wrap>div {
    padding: 12px 8px;
    display: flex;
    align-items: center;
    border: 1px rgba(204, 204, 204, 0.7) solid;
    border-top: 0;
    border-radius: 0 0 8px 8px;
}

.slide_answers_variant input:checked+.answer_wrap>div {
    border-color: transparent;
}

.answer_wrap.answer_wrap_custom-variant>div {
    padding: 0;
}

.answer_wrap .answer-text.custom-answer {
    padding: 18px 18px 18px 58px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgba(225, 225, 225, 0.3);
}

.step-any-slide .answer_wrap input[type="radio"],
.step-any-slide .answer_wrap input[type="checkbox"] {
    display: none;
}

.step-any-slide .answer_wrap .answer_radio .variant_checker,
.step-any-slide .answer_wrap .answer_checkbox .variant_checker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0);
    border: 1px solid transparent;
    border-radius: 3px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.step-any-slide .answer_wrap .answer_radio .variant_checker {
    border-radius: 20px;
}

.step-any-slide input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide input:checked+.answer_wrap .answer_checkbox .variant_checker {
    background: rgba(255, 255, 255, 1);
}

.step-any-slide .answer_wrap .answer_radio .variant_checker:after {
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    content: "";
    background: #66757f;
    transform: scale(0);
    transition: all 0.3s 0s ease;
}

.step-any-slide input:checked+.answer_wrap .answer_radio .variant_checker:after {
    transform: scale(1);
    transition: all 0.3s 0s ease;
}

.step-any-slide .answer_wrap .answer_checkbox .variant_checker:after {
    display: none;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    content: "✓";
    font-weight: 900;
    line-height: 12px;
    color: #66757f;
    transform: scale(0);
    transition: all 0.3s 0s ease;
}

.step-any-slide .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide .variant .answer_wrap .answer_checkbox .variant_checker {
    height: 56px;
    width: 56px;
    right: -28px;
    top: -28px;
    transform: rotate(45deg);
    border: none;
    border-radius: 0;
}

.step-any-slide .variant input+.answer_wrap .answer_radio .variant_checker,
.step-any-slide .variant input+.answer_wrap .answer_checkbox .variant_checker {
    opacity: 0;
}

.step-any-slide .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    opacity: 1;
}

.step-any-slide .variant .answer_wrap .answer_radio .variant_checker svg {
    display: block;
    top: 16px;
    transform: rotate(-45deg);
    position: relative;
}

.step-any-slide .variant .answer_wrap .answer_checkbox .variant_checker svg {
    display: block;
    top: 16px;
    transform: rotate(-45deg);
    position: relative;
}

.step-any-slide input:checked+.answer_wrap .answer_checkbox .variant_checker:after {
    transform: scale(1);
    transition: all 0.3s ease;
}

.step-any-slide .slide_answers_list .answer_wrap>div {
    height: 100%;
    width: 100%;
}

.step-any-slide .slide_answers.variant .answer_wrap>div label+.answer-text p {
    font-size: 14px;
    font-weight: 400;
}

.step-any-slide .slide_answers.list .answer_wrap>div label+.answer-text {
    font-size: 14px;
    font-weight: 700;
    padding-left: 8px;
}

.step-any-slide .list .answer_wrap>div label+.answer-text {
    width: 100%;
    /* text-align: left; */
}

.step-any-slide .slide_answers.list .answer_wrap>div label+.answer-text.custom-answer {
    padding-left: 48px;
}

.step-any-slide .slide_answers.list .answer_wrap .answer_radio .variant_checker,
.step-any-slide .slide_answers.list .answer_wrap .answer_checkbox .variant_checker {
    background: rgba(255, 255, 255, 0);
    position: relative;
    border: 1px solid #ccc;
    top: 0px;
    right: 0px;
}

.step-any-slide .slide_answers.list .answer_wrap.answer_wrap_custom-variant .answer_radio .variant_checker,
.step-any-slide .slide_answers.list .answer_wrap.answer_wrap_custom-variant .answer_checkbox .variant_checker {
    position: absolute;
    top: 18px;
    left: 20px;
}

.answer_blank_input[type="text"] {
    /* height: 40px; */
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    width: 100%;
    padding: 18px 24px;
    transition: all 0s step-end;
    font-size: 16px;
}

.answer_blank_input[type="text"]::placeholder {
    color: #dbdbdb;
}

.any-format-slide_wrap .slide_footer .footer-links a+a {
    display: none;
}

.format_variant .answer_wrap {
    border: 1px #ccc solid;
    background: transparent;
    display: flex;
    flex-direction: column;
}

.format_variant .answer_wrap img {
    width: 100%;
    height: 238px;
    object-fit: cover;
}

/* .format_variant .answer_wrap>div {
    padding: 7px 10px;
} */

.format_list {
    justify-content: end;
}


/* =========================== */


.logic_delete-condition {
    background-image: url(img/icons/delete.svg);
}



/* =========================== */

.step-form-slide {
    background: #ececec;
    border-radius: 0px;
}

.step-form-slide_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 32px 48px;
    position: relative;
    z-index: 10;
}

.step-form-slide .slide_body {
    overflow: hidden auto;
    position: relative;

}

.step-form-slide .slide_description {
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
}

.slide_form_inputs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    max-width: 564px;
    margin: 0 auto;
}

.slide_form_inputs .slide_form_input {
    /* height: 56px; */
    /* background: #f5f5f5;
    border: 1px solid #f5f5f5; */
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    padding: 18px 24px;
    width: 100%;
    text-align: center;
    /* margin-top: 10px; */
    margin: 10px 0 0;
    font-size: 16px;
}

/* .slide_form_inputs .slide_form_input::placeholder {
    color: #767676;
} */

.slide_form_inputs .slide_form_input.vue-tel-input_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.rtl .slide_form_inputs .slide_form_input.vue-tel-input_wrapper {
    flex-direction: row-reverse;
}

.slide_form_inputs .slide_form_input.vue-tel-input_wrapper input {
    /* background: #f5f5f5; */
    text-align: left;
    margin: 0;
    padding: 17px 24px;
}

.vue-tel-input_wrapper {
    display: flex;
}
.vue-tel-input {
    height: 48px;
    margin-right: 8px;
}

.vti__dropdown-list {
    width: 200px;
}

.vti__dropdown-list {
    width: 200px;
}

.vti__input {
    width: 0;
    display: none;
}

.vti__dropdown-arrow {
    color:transparent;
    background: url(img/icons/vue-tel-arrow.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.slide_form_input .vue-tel-input {
    height: 100%;
    /* border: none; */
    /* border-right: 1px solid #ccc; */
    border-width: 1px;
    border-style: solid;
    margin-right: 0;
    border-radius: 5px;
    padding: 14px;
}

.c-ctrl-sform_item-wrap .vue-tel-input_wrapper input[type=text] {
    width: 100%;
}

.vti__dropdown-list.below {
    top: 39px;
    width: 279px;
}


.step-form-slide .quiz_nav {
    /* justify-content: center; */
    /* margin-bottom: 20px; */
    position: relative;
    z-index: 100;
}


.slide_footer .footer-links {
    justify-content: center;
}

.terms-and-policy {
    color: #f2f2f2;
    opacity: 0.6;
    font-size: 10px;
    direction: rtl;
}

.slide_footer .footer-links .terms-and-policy a+a {
    margin: 0;
    color: #f2f2f2;
    opacity: 0.6;
    font-size: 10px;
}

/* ========================= */

.step-finish-slide {
    display: flex;
    background: #ececec;
    border-radius: 0px;
    justify-content: flex-start;
}

.step-finish-slide_wrap>div {
    width: 50%;
}

.step-finish-slide .block_img img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    border-radius: 0;
    position: relative;
    z-index: 10;
}
/* 

.step-finish-slide>img {
    width: 50%;
    border-radius: 10px;
    object-fit: cover;
    position: relative;
        z-index: 10;
} */


.step-finish-slide_wrap {
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
}

.step-finish-slide_wrap>.block_info {
    width: 100%;
}

.step-finish-slide_wrap .block_img+.block_info {
    width: 50%;
}

.step-finish-slide_wrap .block_info {
    height: 100%;
    padding: 10px 60px 10px 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 10;
}

.step-finish-slide .block_info_body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 20px);
}

.step-finish-slide_wrap>.block_info .slide_body {
    align-items: center;
}

.step-finish-slide_wrap .block_img+.block_info .slide_body {
    align-items: baseline;
}

.mobile .step-finish-slide_wrap .block_img+.block_info .slide_body {
    align-items: center;
    justify-content: center;
}

.step-finish-slide .slide_title {
    white-space: pre-wrap;
}

.step-finish-slide_wrap>.block_info .slide_description {
    margin: 20px 0 0;
    padding: 0;
    text-align: center;
}

.step-finish-slide_wrap .block_img+.block_info .slide_description {
    text-align: left;
}

.mobile .step-finish-slide_wrap .block_img+.block_info .slide_description {
    text-align: center;
}

.step-finish_go-to-url {
    display: block;
    background: #324AC7;
    color: #FFF;
    font-weight: 700;
    font-size: 16px;
    padding: 20px;
    text-align: center;
    border: 1px solid #324AC7;
    border-radius: 10px;
    margin-top: 16px;
}


.step-finish-slide_wrap>.block_info .step-finish_go-to-url {
    width: max-content;
}

.step-finish-slide_wrap .block_img+.block_info .step-finish_go-to-url {
    width: 100%;
}

.step-finish_go-to-url:hover {
    transform: scale(1.05);
}

.slide_social-links {
    display: flex;
    gap: 12px;
    margin-top: 16px;
}

.slide_social-links a {
    display: block;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.slide_social-links a svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.slide_social-links a:hover {
    transform: scale(1.1);
}

.slide_social-links a+a {
    /* margin-left: 10px; */
}

/* .step-finish_facebook {
    background-image: url(img/icons/black-facebook.svg);
}
.step-finish_instagram {
    background-image: url(img/icons/black-instagram.svg);
}
.step-finish_telegram {
    background-image: url(img/icons/black-telegram.svg);
}
.step-finish_tiktok {
    background-image: url(img/icons/black-tiktok.svg);
}
.step-finish_youtube {
    background-image: url(img/icons/black-youtube.svg);
} */
.finish_bonus_wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 552px;
}

.step-finish-slide_wrap .block_img+.block_info .finish_bonus_wrap {
    justify-content: flex-start;
}

.finish_bonus_wrap .bonus-block {
    position: relative;
    display: flex;
    align-items: center;
    /* width: calc(50% - 24px); */
    padding: 12px 24px;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
}

.bonus-and-start.two_benefits .bonus-block {
    width: calc(50% - 4px);
}

.bonus-and-start.one_benefit .bonus-block {
    width: 100%;
    max-width: 360px;
}

.finish_bonus_wrap.two_benefits .bonus-block {
    width: calc(50% - 4px);
}

.finish_bonus_wrap.one_benefit .bonus-block {
    width: 100%;
    max-width: 360px;
}

.finish_bonus_wrap .bonus-block img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
    object-fit: contain;
    object-position: center;
}
.finish_bonus_wrap .bonus-block p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    width: calc(100% - 44px);
    display: flex;
    align-items: center;
    height: 40px;
    /* color: inherit; */
}
.finish_bonus_wrap .bonus_description {
    position: absolute;
    transform: scale(0);
    background: rgba(255, 255, 255, 1);
    bottom: 0;
    left: 0;
    /* margin-bottom: calc(100% - 94px); */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 15px;
    padding: 20px 36px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
}
.finish_bonus_wrap .benefit_label_swither:checked+.bonus-block .bonus_description {
    transform: scale(1);
}

.step-finish-slide .slide_footer {
    margin-top: auto;
}


/* ======================== */
.slide_footer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: auto;
}

.quiz_nav {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-left: auto;
    position: relative;
    z-index: 100;
}

.quiz_nav a,
.quiz_nav input[type=button],
.quiz_nav button {
    height: 56px;
    background-color: rgba(255, 255, 255, 0);
    background-position: center;
    background-size: 56px;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;
}

.quiz_nav .step_back {
    display: block;
    width: 56px;
    background-repeat: no-repeat;
    margin-right: 20px;
    /* background-image: url(https://app.adsquiz.io/img/icons/scroll-slide-inactive.png); */
    border-color: rgba(255, 255, 255, 0);
    background: none;
    cursor: pointer;
}

.quiz_nav .step_forvard {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 126px;
    background-repeat: no-repeat;
    /* background-image: url(https://app.adsquiz.io/img/icons/scroll-slide-active.png); */
    border: 1px solid transparent;
    background-color: transparent;
    background-position: center;
    border-radius: 10px;
}

.quiz_nav .step_send {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background-color: transparent;
    /* background-image: url(img/icons/send_lead_plane.svg);
        background-size: 21px 21px;
        background-repeat: no-repeat;
        background-position: 24px center; */
    color: #fff;
    width: auto;
    font-weight: 700;
    font-size: 16px;
    padding: 16px 24px 16px 24px;
    height: auto;
    cursor: pointer;
}
.quiz_nav .step_send svg {
    margin-right: 16px;
}

.rtl .quiz_nav .step_send svg {
    transform: rotate(270deg);
    margin-right: 0;
    margin-left: 16px;
}

.quiz_nav .step_back:hover {
    box-shadow: none;
    cursor: pointer;
}
.footer-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.slide_footer .footer-links {
    position: absolute;
    bottom: 0;
    z-index: 99;
}

.slide_footer .footer-links a {
    padding: 0;
    color: #F2F2F2;
    font-size: 10px;
}

.slide_footer .footer-links a span {
    color: #324AC7;
    font-weight: 700;
    font-size: 14px;
}

.slide_footer .footer-links a+a {
    margin-left: 50px;
}

.any-format-slide_wrap .slide_footer .footer-links a+a {
    display: none;
}

.label_false .slide_footer .footer-links {
    justify-content: end;
}

.label_false .slide_footer .footer-links a:first-of-type {
    display: none
}

.step-first-slide.label_false .slide_footer .footer-links a {
    display: none
}
.step-first-slide.label_false .slide_footer .footer-links a {
    display: none
}

.step-finish-slide_wrap .slide_footer .footer-links {
    position: relative;
    width: auto;
    margin-left: auto;
    margin-top: 8px;
}
/* ========================== */
.constructor-body_steps {
    height: 44px;
    width: 100%;
}

.constructor-body_steps nav {
    display: flex;
    padding: 10px;
    margin: 0;
    /* overflow: auto; */
}

.constructor-body_steps nav a {
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
    list-style: none;
    background: #fff;
    border: none;
    border-radius: 8px;
    line-height: 1.7em;
    text-align: center;
    color: #bcbcbc;
}

/* .constructor-body_steps nav a:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.constructor-body_steps nav a.active {
    border: 1px solid rgba(218, 218, 218, 1);
} */

.constructor-body_steps nav a:hover,
.constructor-body_steps nav a:focus,
.constructor-body_steps nav a.router-link-exact-active {
color: #5d5d5d;
box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3);
}

.constructor-body_steps_drag {
    display: flex;
}

.constructor-body_steps nav .one_step_pan a  {
    margin-right: 0;
}

.one_step_pan {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.one_step_pan .c-ctrl-s_move {
    opacity: 0;
    margin-top: 3px;
}

.one_step_pan:hover .c-ctrl-s_move {
    opacity: 1;
}

.constructor-body_steps_add-new {
    display: block;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 10px;
    list-style: none;
    background: #fff;
    border: none;
    border-radius: 8px;
    line-height: 1em;
    text-align: center;
    color: #5c5c5c;
}




/* ================== */


.question_logic_setup_btn {
    position: relative;
}

.question_logic_setup_btn:disabled {
    background-color: var(--color-main-light-gray);
    color: var(--color-main-dark-gray);
}

.question_logic_setup_btn:disabled svg path {
    fill: var(--color-main-dark-gray);
}

.question_logic_setup_btn .data-v-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.question_logic_setup_btn .data-v-tooltip::after {
    max-width: 90%;
}

.question_logic_setup_btn .conditions_counter {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-main-white);
    background-color: var(--color-main-blue);
}

.setting_integration_active_overlay {
    display: none;
    width: 20%;
    height: 100%;
}









/* ================== */

/* .constructor_adaptive {
    width: 40px;
} */

.adaptive_button_wrap {
    display: flex;
    flex-direction: row;
    border-radius: 8px;
}

.hide_delete_step_button_wrap {
    display: flex;
    flex-direction: row;
}

.delete_step {
    margin-left: 16px;
}

.constructor_adaptive button {
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 8px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor:pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .constructor_adaptive button+button {
    margin-top: 10px;
} */

.constructor_adaptive button svg {
    width: 24px;
    height: 24px;
}



/* =================== */


/* Here the end default theme settings */

.step-any-slide.wrap_scaling .ps--active-y>.ps__rail-y,
.step-any-slide.wrap_scaling .ps--active-x>.ps__rail-x {
    display: none;
}

/* ====================== */


.theme_1.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_1.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_1.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_1.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_1.step-first-slide-format_row.full_bg .block_info, .theme_1.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_2.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_2.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_2.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_2.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_2.step-first-slide-format_row.full_bg .block_info, .theme_2.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_3.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_3.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_3.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_3.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_3.step-first-slide-format_row.full_bg .block_info, .theme_3.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_4.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_4.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_4.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_4.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_4.step-first-slide-format_row.full_bg .block_info, .theme_4.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_5.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_5.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_5.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_5.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_5.step-first-slide-format_row.full_bg .block_info, .theme_5.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_6.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_6.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_6.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_6.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_6.step-first-slide-format_row.full_bg .block_info, .theme_6.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_7.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_7.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_7.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_7.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_7.step-first-slide-format_row.full_bg .block_info, .theme_7.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_8.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_8.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_8.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_8.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_8.step-first-slide-format_row.full_bg .block_info, .theme_8.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_9.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_9.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_9.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_9.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_9.step-first-slide-format_row.full_bg .block_info, .theme_9.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_10.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_10.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_10.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_10.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_10.step-first-slide-format_row.full_bg .block_info, .theme_10.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_11.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_11.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_11.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_11.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_11.step-first-slide-format_row.full_bg .block_info, .theme_11.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_12.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_12.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_12.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_12.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_12.step-first-slide-format_row.full_bg .block_info, .theme_12.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_13.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_13.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_13.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_13.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_13.step-first-slide-format_row.full_bg .block_info, .theme_13.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_14.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_14.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_14.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_14.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_14.step-first-slide-format_row.full_bg .block_info, .theme_14.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}
.theme_15.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_15.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_15.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_15.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_15.step-first-slide-format_row.full_bg .block_info, .theme_15.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_16.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_16.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_16.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_16.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_16.step-first-slide-format_row.full_bg .block_info, .theme_16.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_17.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, #272626,transparent);} .theme_17.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, #272626,transparent);} .theme_17.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_17.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, #272626,transparent);} .theme_17.step-first-slide-format_row.full_bg .block_info, .theme_17.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(255, 255, 255, 0.3)}
.theme_1_white.step-first-slide-format_row.full_bg .block_img .bg_darker {background: linear-gradient(270deg, rgba(255, 255, 255, 0.9) 43.44%, rgba(255, 255, 255, 0.18) 100%);} .theme_1_white.step-first-slide-format_row.row_rev.full_bg .block_img .bg_darker {background: linear-gradient(90deg, rgba(255, 255, 255, 0.9) 45.89%, rgba(255, 255, 255, 0.18) 100%);} .theme_1_white.step-first-slide-format_blank.full_bg .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 49.04%, rgba(255, 255, 255, 0.18) 100%);} .theme_1_white.step-first-slide.full_bg.mobile .block_img .bg_darker {background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0.18) 100%);} .theme_1_white.step-first-slide-format_row.full_bg .block_info, .theme_1_white.step-first-slide-format_row.row_rev.full_bg .block_info {border-color: rgba(0, 0, 0, 0.3)}


/* ====================== */
/* .variant_checker {
    display: none!important;
} */


input.answer-text.custom-answer:focus::placeholder {
    color: transparent
}

/* ===================== */
/* .mobile .constructor-body_slide {
    min-height: 700px;
    width: 375px;
    min-width: 375px;
    max-width: 375px;
} */

.mobile .step-first-slide_wrap,
.row_rev.mobile .step-first-slide_wrap {
    flex-direction: column;
    overflow: auto;
}
 
.step-first-slide-format_column.mobile .step-first-slide_wrap {
    padding: 0;
}
.step-first-slide-format_blank.mobile .step-first-slide_wrap {
    padding: 0;
}
.step-first-slide-format_row.mobile .step-first-slide_wrap>div {
    width: 100%;
}
.step-first-slide.mobile .step-first-slide_wrap>div.block_img {
    /* min-height: 40%; */
    height: 40%;
}
.step-first-slide-format_blank.mobile .step-first-slide_wrap>div.block_img {
    height: 0;
    opacity: 0
}

.step-first-slide.mobile .block_info {
    padding: 20px 22px 0 22px;
    min-height: 60%;
    /* height: calc(100% - 220px); */
}

.step-first-slide-format_blank.mobile .block_info {
    height: 100%;
}

.step-first-slide.mobile .block_info h1 {
    font-size: 28px;
    line-height: 40px;
    margin-top: 0;
    text-align: center;
}
.step-first-slide.mobile .block_info_body>p {
    text-align: center;
}
.step-first-slide-format_row.mobile .block_info_body {
    padding-top: 10%;
    align-items: center;
    justify-content: flex-start;
}
.step-first-slide.mobile .bonus-and-start {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px 8px;
}

.step-first-slide-format_column.mobile .block_info_body {
    padding-top: 10%;
    align-items: center;
    justify-content: flex-start;
}
.step-first-slide-format_column.mobile .bonus-block {
    margin-right: 0;
}
.step-first-slide.mobile .start {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
    width: 100%;
    /* position: absolute;
    bottom: 45px;
    margin-top: auto; */
}
.step-first-slide.mobile .footer-links a {
    padding: 0;
}
.step-first-slide.full_bg.mobile .step-first-slide_wrap>div.block_img {
    height: 0;
    opacity: 1;
}

.step-first-slide.full_bg.mobile .block_info {
    height: 100%;
}
.step-first-slide.full_bg.mobile .block_info_body {
    justify-content: center;
    padding-top: 0;
    padding-bottom: 0 0 95px 0;
}
/* ================= */
.constructor_body.mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* width: 370px; */
    /* width: 100%; */
    /* max-width: 450px; */
    /* background: #fff; */
    border-radius: 10px;
    padding: 0 10px;
}
.mobile .constructor-body_slide {
    margin: 0 auto;
    height: 700px;
    min-height: 700px;
    max-height: calc(100% - 44px);
    
    width: 375px;
    min-width: 375px;
    max-width: 375px;
}
.mobile .any-format-slide_wrap {
    flex-direction: column;
}
.mobile .any-format-slide_wrap .block_info {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.mobile .any-format-slide_wrap .block_info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px 10px 15px;
    transition: none;
}

.mobile .any-format-slide_wrap .block_img+.block_info {
    height: calc(100% - 220px);
}

.mobile  .slide_progresbar {
    transition:all 0s step-end;
    /* width: 160px; */
}
.mobile .format_list .slide_progresbar,
.mobile .format_variant .slide_progresbar {
    margin-right: 15px;
}
.mobile .format_list .slide_title,
.mobile .format_list .ol,
.mobile .format_list .ul,
.mobile .format_variant .slide_title {
    font-size: 18px;
    line-height: 25px;
    /* color: #000000; */
    text-transform: uppercase;
    margin: 0;
    margin-top: 20px;
    margin-right: 15px;
}

.mobile .step-any-slide.format_custom {
    flex-direction: column;
}

/* .mobile .step-any-slide.format_custom>img {
    width: 100%;
    height: 220px;
} */

.mobile .step-any-slide.format_custom .block_img {
    width: 100%;
    height: 220px;
}
.mobile .any-format-slide_wrap {
    padding: 0;
}
.mobile .step-any-slide.format_custom .any-format-slide_wrap .block_info {
    padding: 20px 15px 10px 15px;
}
.mobile .step-any-slide.format_range .any-format-slide_wrap .block_info {
    padding: 20px 15px 10px 15px;
}
.mobile .format_variant .answers_wrap .ps {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.mobile .format_variant .answer_wrap+.answer_wrap {
    margin: 0 0 15px 0;
}
.mobile .slide_answers_variant .answer_wrap {
    width: 48%;
    margin: 0 0 15px 0;
}
.mobile .slide_answers_list .answer_wrap {
    width: 100%;
    margin: 0 0 15px 0;
}
.mobile .step-any-slide.format_variant .answer_wrap img {
    width: 100%;
    height: 105px;
}

.mobile .answers_wrap {
    flex-direction: column;
}
.mobile .answers_wrap .ps {
    padding-right: 15px;
}
.mobile .answers_wrap .ps .ps__thumb-y {
    right: 0;
    transition: none;
    background: #aaa;
    opacity: 1!important;
}
.mobile .ps__rail-y:hover>.ps__thumb-y,
.mobile .ps__rail-y:focus>.ps__thumb-y,
.mobile .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #999;
    width: 6px;
}
.mobile .ps__thumb-y:hover,
.mobile .ps__thumb-y:focus,
.mobile .ps__rail-y.ps--clicking .ps__thumb-y {
    width: 6px;
}
.mobile .answer_wrap {
    width: 100%;
    margin: 0 0 10px 0;
}
.mobile .quiz_nav {
    transition: all 0s step-end;
}

.mobile .format_list .quiz_nav,
.mobile .format_variant .quiz_nav {
    padding-right: 15px;
}
.mobile .any-format-slide_wrap .quiz_nav a,
.mobile .any-format-slide_wrap .quiz_nav input[type=button] {
    width: 80px;
    height: 40px;
    background-size: 32px;
}
/* .quiz_nav .step_back {
    margin-right: 5px;
} */
.mobile .format_list .footer-links,
.mobile .format_variant .footer-links {
    padding-right: 15px;
}
/* ================= */
.mobile .step-form-slide_wrap {
    padding: 20px 15px 10px 15px;
}
.step-form-slide.mobile .slide_description {
    width: 100%;
}
.mobile .slide_form_inputs>div  {
    width: 100%;
}
.mobile .slide_form_inputs .slide_form_input {
    /* width: 100%!important; */
    max-width: 100%;
    margin: 10px 0 0 0;
}
.mobile .slide_form_inputs .slide_form_input.vue-tel-input_wrapper input {
    width: 185px;
}
.mobile .slide_form_checkboxes {
    margin: 16px 0 0 0;
}

.step-form-slide.mobile .quiz_nav {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
    margin-bottom: 16px;

}
.step-form-slide.mobile .quiz_nav .step_send,
.step-form-slide.mobile .quiz_nav input[type=button].step_send {
    width: 100%;
    max-width: 360px;
}
/* =============================== */
.mobile .step-finish-slide_wrap {
    flex-direction: column;
}

.mobile .step-finish-slide_wrap>.block_img {
    height: 220px;
    width: 100%;
}

.mobile.step-finish-slide .block_info {
    padding: 20px 15px 10px 15px;
    width: 100%;
    height: 100%;
}

.mobile.step-finish-slide .block_img+.block_info {
    padding: 20px 15px 10px 15px;
    width: 100%;
    height: calc(100% - 220px);
}

.mobile .step-finish-slide .block_info_body {
    padding: 0;
}

/* .mobile.step-finish-slide .slide_header {
    display: none;
} */

.mobile.step-finish-slide .slide_body {
    text-align: center;
    height: 100%;
}

.mobile.step-finish-slide .slide_title {
    margin: 0;
    font-size: 28px;
    line-height: 40px;
    text-align: center;
}

.mobile.step-finish-slide .slide_description {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    /* width: 70%; */
    margin: 0 auto;
    text-align: center;
}

.mobile.step-finish-slide .step-finish_go-to-url {
    margin: 0;
}

.mobile.step-finish-slide .slide_social-links {
    margin: 0 auto;
}

.mobile.step-finish-slide .slide_footer {
    margin-top: 20px;
}

/* .mobile .footer-links {
    display: none;
} */

/* =================== */

.ui-widget.ui-widget-content {
    border: 1px solid rgba(0, 0, 0, 0.0);
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
    border-bottom-right-radius: 10px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 10px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
    border-top-right-radius: 10px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 10px;
}
.ui-widget-content {
    border: 1px solid rgba(0, 0, 0, 0.0);
    background: rgba(0, 0, 0, 0.1);
    color: #333333;
}

.ui-widget {
    font-family: Arial,Helvetica,sans-serif;
    font-size: 1em;
}
.ui-progressbar {
    height: 10px;
    text-align: left;
    overflow: hidden;
}
.ui-progressbar .ui-progressbar-value {
    margin: 2px;
    height: 4px;
}
.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
    border-bottom-left-radius: 10px;
}
.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    border-top-left-radius: 10px;
}
.ui-widget-header {
    border: 1px solid rgba(0, 0, 0, 0.0);
    background: rgba(0, 0, 0, 0.4);
    color: #333333;
    font-weight: bold;
    border-radius: 10px;
}

.slide_header {
    width: 100%;
    display: flex;
    justify-content: center;
}

.slide_progresbar {
    display: block;
    width: calc(100% + 86px);
    margin: -28px -56px 0;
    height: 6px;
    padding: 1px 2px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

img+.any-format-slide_wrap .slide_progresbar {
    display: block;
    width: calc(100% + 86px);
}

.progresbar_line {
    display: block;
    width: 0%;
    height: 100%;
    background: #324AC7;
    border-radius: 10px;
}
.open_quiz_on_mobile {
    display: none;
}
/* ================ */

.step_forvard svg,
.step_back svg {
    fill: inherit;
}

.step_back svg path,
.step_forvard svg path {
    fill: inherit;
}







/* ================ */

.constructor-body_slide.theme_1 {
    background: #e6e6e6;
}

.constructor-body_slide.theme_1 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_1 .progresbar_line {
    background: #324ac7;
}

.theme_1 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_1 .quiz_nav .step_forvard {
    background-color: #324ac7;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_1 .ps--active-x>.ps__rail-x,
.theme_1 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_1 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_1 .footer-links a span {
    color: #808080;
}

.theme_1 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_1 .slide_title,
.theme_1 .ol,
.theme_1 .ul,
.theme_1 li,
.theme_1 h1,
.theme_1 h2,
.theme_1 h3,
.theme_1 p {
    color: #000000;
}

.theme_1 .slide_description {
    color: #000000;
}

.step-first-slide.theme_1 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_1 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_1 .bonus-block,
.theme_1 .finish_bonus_wrap .bonus-block,
.theme_1 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .5);
}

.step-first-slide.theme_1 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_1 .start {
    background: #324ac7;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_1 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_1 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_1 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_1 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_1 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #324ac7;
} */
.step-any-slide.theme_1 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #324ac7;
}

.step-any-slide.theme_1 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #fff;
}

.step-any-slide.theme_1 .variant .answer_wrap .answer_radio .variant_checker:after,
.step-any-slide.theme_1 .variant .answer_wrap .answer_checkbox .variant_checker:after {
    color: #fff;
}

.step-any-slide.theme_1 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_1 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_1 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_1 .variant input:checked+.answer_wrap {
    border-color: #324ac7;
}

.step-any-slide.theme_1 input:checked+.answer_wrap:after {
    background: #324ac7;
}

.step-any-slide.theme_1 input:checked+.answer_wrap svg path {
    stroke: #fff;
}

.step-any-slide.theme_1 .list input:checked+.answer_wrap,
.step-any-slide.theme_1 .variant input:checked+.answer_wrap {
    border-color: #324ac7;
    background-color: #324ac7;
}

.step-any-slide.theme_1 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #324ac7;
    background-color: #324ac7;
}


.step-any-slide.theme_1 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_1 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_1 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_1 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_1 .answer_wrap:hover,
.theme_1 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #324ac7;
}

.theme_1 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_1 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_1 .slide_form_inputs .slide_form_input,
.theme_1 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: #fff;
    background-color: #f5f5f5;
}

.theme_1 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_1 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #324ac7;
    color: #ffffff;
    fill: #ffffff;
}

.theme_1 .step-finish_go-to-url {
    background-color: #324ac7;
    border-color: transparent;
    color: #ffffff;
}

.theme_1 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_1 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_1 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_2 {
    background: #e6e6e6;
}

.constructor-body_slide.theme_2 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_2 .progresbar_line {
    background: #9d324c;
}

.theme_2 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_2 .quiz_nav .step_forvard {
    background-color: #9d324c;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_2 .ps--active-x>.ps__rail-x,
.theme_2 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_2 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_2 .footer-links a span {
    color: #808080;
}

.theme_2 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_2 .slide_title,
.theme_2 .ol,
.theme_2 .ul,
.theme_2 li,
.theme_2 h1,
.theme_2 h2,
.theme_2 h3,
.theme_2 p {
    color: #000000;
}

.theme_2 .slide_description {
    color: #000000;
}

.step-first-slide.theme_2 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_2 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_2 .bonus-block,
.theme_2 .finish_bonus_wrap .bonus-block,
.theme_2 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .5);
}

.step-first-slide.theme_2 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_2 .start {
    background: #9d324c;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_2 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_2 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_2 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_2 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_2 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_2 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #9d324c;
} */
.step-any-slide.theme_2 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #9d324c;
}

.step-any-slide.theme_2 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #fff;
}

.step-any-slide.theme_2 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_2 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_2 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_2 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_2 .variant input:checked+.answer_wrap {
    border-color: #9d324c;
}

.step-any-slide.theme_2 input:checked+.answer_wrap:after {
    background: #9d324c;
}

.step-any-slide.theme_2 input:checked+.answer_wrap svg path {
    stroke: #fff;
}

.step-any-slide.theme_2 .list input:checked+.answer_wrap,
.step-any-slide.theme_2 .variant input:checked+.answer_wrap {
    border-color: #9d324c;
    background-color: #9d324c;
}

.step-any-slide.theme_2 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_2 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #9d324c;
    background-color: #9d324c;
}


.step-any-slide.theme_2 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_2 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_2 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_2 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_2 .answer_wrap:hover,
.theme_2 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #9d324c;
}

.theme_2 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_2 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_2 .slide_form_inputs .slide_form_input,
.theme_2 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_2 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_2 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #9d324c;
    color: #ffffff;
    fill: #ffffff;
}

.theme_2 .step-finish_go-to-url {
    background-color: #9d324c;
    border-color: transparent;
    color: #ffffff;
}

.theme_2 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_2 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_2 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_3 {
    background: #e6e6e6;
}

.constructor-body_slide.theme_3 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_3 .progresbar_line {
    background: #463cb5;
}

.theme_3 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_3 .quiz_nav .step_forvard {
    background-color: #463cb5;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_3 .ps--active-x>.ps__rail-x,
.theme_3 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_3 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_3 .footer-links a span {
    color: #808080;
}

.theme_3 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_3 .slide_title,
.theme_3 .ol,
.theme_3 .ul,
.theme_3 li,
.theme_3 h1,
.theme_3 h2,
.theme_3 h3,
.theme_3 p {
    color: #000000;
}

.theme_3 .slide_description {
    color: #000000;
}

.step-first-slide.theme_3 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_3 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_3 .bonus-block,
.theme_3 .finish_bonus_wrap .bonus-block,
.theme_3 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .5);
}

.step-first-slide.theme_3 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_3 .start {
    background: #463cb5;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_3 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_3 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_3 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_3 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_3 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_3 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #463cb5;
} */
.step-any-slide.theme_3 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #463cb5;
}

.step-any-slide.theme_3 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #fff;
}

.step-any-slide.theme_3 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_3 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_3 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_3 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_3 .variant input:checked+.answer_wrap {
    border-color: #463cb5;
}

.step-any-slide.theme_3 input:checked+.answer_wrap:after {
    background: #463cb5;
}

.step-any-slide.theme_3 input:checked+.answer_wrap svg path {
    stroke: #fff;
}

.step-any-slide.theme_3 .list input:checked+.answer_wrap,
.step-any-slide.theme_3 .variant input:checked+.answer_wrap {
    border-color: #463cb5;
    background-color: #463cb5;
}

.step-any-slide.theme_3 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_3 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #463cb5;
    background-color: #463cb5;
}


.step-any-slide.theme_3 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_3 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_3 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_3 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_3 .answer_wrap:hover,
.theme_3 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #463cb5;
}

.theme_3 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_3 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_3 .slide_form_inputs .slide_form_input,
.theme_3 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_3 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_3 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #463cb5;
    color: #ffffff;
    fill: #ffffff;
}

.theme_3 .step-finish_go-to-url {
    background-color: #463cb5;
    border-color: transparent;
    color: #ffffff;
}

.theme_3 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_3 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_3 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_4 {
    background: #e6e6e6;
}

.constructor-body_slide.theme_4 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_4 .progresbar_line {
    background: #1c8f6a;
}

.theme_4 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_4 .quiz_nav .step_forvard {
    background-color: #1c8f6a;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_4 .ps--active-x>.ps__rail-x,
.theme_4 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_4 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_4 .footer-links a span {
    color: #808080;
}

.theme_4 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_4 .slide_title,
.theme_4 .ol,
.theme_4 .ul,
.theme_4 li,
.theme_4 h1,
.theme_4 h2,
.theme_4 h3,
.theme_4 p {
    color: #000000;
}

.theme_4 .slide_description {
    color: #000000;
}

.step-first-slide.theme_4 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_4 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_4 .bonus-block,
.theme_4 .finish_bonus_wrap .bonus-block,
.theme_4 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .5);
}

.step-first-slide.theme_4 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_4 .start {
    background: #1c8f6a;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_4 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_4 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_4 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_4 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_4 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_4 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #1c8f6a;
} */
.step-any-slide.theme_4 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #1c8f6a;
}

.step-any-slide.theme_4 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #fff;
}

.step-any-slide.theme_4 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_4 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_4 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_4 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_4 .variant input:checked+.answer_wrap {
    border-color: #1c8f6a;
}

.step-any-slide.theme_4 input:checked+.answer_wrap:after {
    background: #1c8f6a;
}

.step-any-slide.theme_4 input:checked+.answer_wrap svg path {
    stroke: #fff;
}

.step-any-slide.theme_4 .list input:checked+.answer_wrap,
.step-any-slide.theme_4 .variant input:checked+.answer_wrap {
    border-color: #1c8f6a;
    background-color: #1c8f6a;
}

.step-any-slide.theme_4 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_4 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #1c8f6a;
    background-color: #1c8f6a;
}


.step-any-slide.theme_4 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_4 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_4 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_4 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_4 .answer_wrap:hover,
.theme_4 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #1c8f6a;
}

.theme_4 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_4 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_4 .slide_form_inputs .slide_form_input,
.theme_4 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_4 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_4 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #1c8f6a;
    color: #ffffff;
    fill: #ffffff;
}

.theme_4 .step-finish_go-to-url {
    background-color: #1c8f6a;
    border-color: transparent;
    color: #ffffff;
}

.theme_4 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_4 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_4 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_5 {
    background: #21252a;
}

.constructor-body_slide.theme_5 .slide_progresbar {
    background: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_5 .progresbar_line {
    background: rgba(255, 255, 255, 0.5);
}

.theme_5 .quiz_nav .step_back svg path{
    color: #64666a;
    fill: #64666a;
}

.theme_5 .quiz_nav .step_forvard {
    background-color: #fff;
    border-color: #ffffff;
    fill: #64666A;
    color: #64666A;
}

.theme_5 .ps--active-x>.ps__rail-x,
.theme_5 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.05);
}

.constructor-body_slide.theme_5 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_5 .footer-links a span {
    color: #808080;
}

.theme_5 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_5 .slide_title,
.theme_5 .ol,
.theme_5 .ul,
.theme_5 li,
.theme_5 h1,
.theme_5 h2,
.theme_5 h3,
.theme_5 p {
    color: #ffffff;
}

.theme_5 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_5 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_5 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_5 .bonus-block,
.theme_5 .finish_bonus_wrap .bonus-block,
.theme_5 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(255, 255, 255, .5);
    background: #2F353D;
}

.step-first-slide.theme_5 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_5 .start {
    background: #eff5fa;
    border-color: transparent;
    color: #271e2b;
}

.step-any-slide.theme_5 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_5 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_5 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_5 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_5 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_5 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_5 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_5 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #21252a;
}

.step-any-slide.theme_5 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.5); */
    border-color: transparent;
}

.step-any-slide.theme_5 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_5 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_5 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_5 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_5 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_5 input:checked+.answer_wrap svg path {
    stroke: #21252a;
}

.step-any-slide.theme_5 .list input:checked+.answer_wrap,
.step-any-slide.theme_5 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}

.step-any-slide.theme_5 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_5 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}


.step-any-slide.theme_5 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_5 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #21252a;
}

.step-any-slide.theme_5 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #21252a;
}

.step-any-slide.theme_5 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #21252a;
}

.theme_5 .answer_wrap:hover,
.theme_5 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #21252a;
}

.theme_5 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgb(245, 245, 245, .12);
    color: #fff;
}

.theme_5 .answer_blank_input[type="text"]::placeholder {
    color: #9CA3A8;
}

.theme_5 .slide_form_inputs .slide_form_input,
.theme_5 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background: rgb(245, 245, 245, .12);
    color: #fff;
}

.theme_5 .slide_form_inputs .slide_form_input::placeholder {
    color: #9CA3A8;
}

.theme_5 .quiz_nav .step_send {
    border-color: #ffffff;
    background-color: transparent;
    color: #ffffff;
    fill: #ffffff;
}

.theme_5 .step-finish_go-to-url {
    background-color: #ffffff;
    border-color: transparent;
    color: #21252a;
}

.theme_5 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_5 .step-finish-slide .slide_social-links {
    fill: white;
}

.theme_5 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_6 {
    background: #0a1e27;
}

.constructor-body_slide.theme_6 .slide_progresbar {
    background: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_6 .progresbar_line {
    background: #6f21d8;
}

.theme_6 .quiz_nav .step_back svg path{
    color: #64666a;
    fill: #64666a;
}

.theme_6 .quiz_nav .step_forvard {
    background-color: #6f21d8;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_6 .ps--active-x>.ps__rail-x,
.theme_6 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.05);
}

.constructor-body_slide.theme_6 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_6 .footer-links a span {
    color: #808080;
}

.theme_6 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_6 .slide_title,
.theme_6 .ol,
.theme_6 .ul,
.theme_6 li,
.theme_6 h1,
.theme_6 h2,
.theme_6 h3,
.theme_6 p {
    color: #ffffff;
}

.theme_6 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_6 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_6 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_6 .bonus-block,
.theme_6 .finish_bonus_wrap .bonus-block,
.theme_6 .quiz_slides_benefits .benefit_wrap {
    border-color: #ffffff;
    background: #0D2834;
}

.step-first-slide.theme_6 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_6 .start {
    background: #6f21d8;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_6 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_6 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_6 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_6 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_6 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_6 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_6 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_6 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #6f21d8;
}

.step-any-slide.theme_6 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.5); */
    border-color: transparent;
}

.step-any-slide.theme_6 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_6 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_6 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_6 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_6 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_6 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_6 .list input:checked+.answer_wrap,
.step-any-slide.theme_6 .variant input:checked+.answer_wrap {
    border-color: #6f21d8;
    background-color: #6f21d8;
}

.step-any-slide.theme_6 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_6 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #6f21d8;
    background-color: #6f21d8;
}


.step-any-slide.theme_6 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_6 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #f5f5f5;
}

.step-any-slide.theme_6 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #f5f5f5;
}

.step-any-slide.theme_6 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #f5f5f5;
}

.theme_6 .answer_wrap:hover,
.theme_6 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #6f21d8;
}

.theme_6 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_6 .answer_blank_input[type="text"]::placeholder {
    color: #9CA3A8;
}

.theme_6 .slide_form_inputs .slide_form_input,
.theme_6 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_6 .slide_form_inputs .slide_form_input::placeholder {
    color: #9CA3A8;
}

.theme_6 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #6f21d8;
    color: #ffffff;
    fill: #ffffff;
}

.theme_6 .step-finish_go-to-url {
    background-color: #6f21d8;
    border-color: transparent;
    color: #ffffff;
}

.theme_6 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_6 .step-finish-slide .slide_social-links {
    fill: white;
}

.theme_6 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_7 {
    background: #002d2d;
}

.constructor-body_slide.theme_7 .slide_progresbar {
    background: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_7 .progresbar_line {
    background: #006052;
}

.theme_7 .quiz_nav .step_back svg path{
    color: #4c6c6c;
    fill: #4c6c6c;
}

.theme_7 .quiz_nav .step_forvard {
    background-color: #006052;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_7 .ps--active-x>.ps__rail-x,
.theme_7 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.05);
}

.constructor-body_slide.theme_7 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_7 .footer-links a span {
    color: #808080;
}

.theme_7 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_7 .slide_title,
.theme_7 .ol,
.theme_7 .ul,
.theme_7 li,
.theme_7 h1,
.theme_7 h2,
.theme_7 h3,
.theme_7 p {
    color: #ffffff;
}

.theme_7 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_7 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_7 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_7 .bonus-block,
.theme_7 .finish_bonus_wrap .bonus-block,
.theme_7 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(245, 245, 245, .12);
    background: #013D3D;
}

.step-first-slide.theme_7 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_7 .start {
    background: #006052;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_7 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_7 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_7 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_7 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_7 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_7 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_7 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_7 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #006052;
}

.step-any-slide.theme_7 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.5); */
    border-color: transparent;
}

.step-any-slide.theme_7 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_7 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_7 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_7 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_7 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_7 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_7 .list input:checked+.answer_wrap,
.step-any-slide.theme_7 .variant input:checked+.answer_wrap {
    border-color: #006052;
    background-color: #006052;
}

.step-any-slide.theme_7 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_7 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #006052;
    background-color: #006052;
}


.step-any-slide.theme_7 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_7 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #f5f5f5;
}

.step-any-slide.theme_7 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #f5f5f5;
}

.step-any-slide.theme_7 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #f5f5f5;
}

.theme_7 .answer_wrap:hover,
.theme_7 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #006052;
}

.theme_7 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_7 .answer_blank_input[type="text"]::placeholder {
    color: #9CA3A8;
}

.theme_7 .slide_form_inputs .slide_form_input,
.theme_7 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_7 .slide_form_inputs .slide_form_input::placeholder {
    color: #9CA3A8;
}

.theme_7 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #006052;
    color: #ffffff;
    fill: #ffffff;
}

.theme_7 .step-finish_go-to-url {
    background-color: #006052;
    border-color: transparent;
    color: #ffffff;
}

.theme_7 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_7 .step-finish-slide .slide_social-links {
    fill: white;
}

.theme_7 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_8 {
    background: #e5f6ee;
}

.constructor-body_slide.theme_8 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_8 .progresbar_line {
    background: #5f9b8c;
}

.theme_8 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_8 .quiz_nav .step_forvard {
    background-color: #5f9b8c;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_8 .ps--active-x>.ps__rail-x,
.theme_8 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_8 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_8 .footer-links a span {
    color: #808080;
}

.theme_8 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_8 .slide_title,
.theme_8 .ol,
.theme_8 .ul,
.theme_8 li,
.theme_8 h1,
.theme_8 h2,
.theme_8 h3,
.theme_8 p {
    color: #000000;
}

.theme_8 .slide_description {
    color: #000000;
}

.step-first-slide.theme_8 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_8 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_8 .bonus-block,
.theme_8 .finish_bonus_wrap .bonus-block,
.theme_8 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: #DAEFE5;
}

.step-first-slide.theme_8 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_8 .start {
    background: #5f9b8c;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_8 .list .answer_wrap {
    border-color: #000;
}

.step-any-slide.theme_8 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_8 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #000;
}

.step-any-slide.theme_8 .answer_wrap .answer_radio .variant_checker:after {
    background: #000;
}

.step-any-slide.theme_8 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_8 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #5f9b8c;
} */
.step-any-slide.theme_8 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #5f9b8c;
}

.step-any-slide.theme_8 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #5f9b8c;
}

.step-any-slide.theme_8 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_8 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_8 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_8 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_8 .variant input:checked+.answer_wrap {
    border-color: #5f9b8c;
}

.step-any-slide.theme_8 input:checked+.answer_wrap:after {
    background: #5f9b8c;
}

.step-any-slide.theme_8 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_8 .list input:checked+.answer_wrap,
.step-any-slide.theme_8 .variant input:checked+.answer_wrap {
    border-color: #5f9b8c;
    background-color: #5f9b8c;
}

.step-any-slide.theme_8 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_8 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #5f9b8c;
    background-color: #5f9b8c;
}


.step-any-slide.theme_8 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_8 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_8 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_8 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_8 .answer_wrap:hover,
.theme_8 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #5F9B8C;
}

.theme_8 .answer_blank_input[type="text"] {
    border-color: #5f9b8c;
    background: rgba(245, 245, 245, .12);
}

.theme_8 .answer_blank_input[type="text"]::placeholder {
    color: #9CA3A8;
}

.theme_8 .slide_form_inputs .slide_form_input,
.theme_8 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: #5f9b8c;
    background: rgba(245, 245, 245, .12);
}

.theme_8 .slide_form_inputs .slide_form_input::placeholder {
    color: #9CA3A8;
}

.theme_8 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #5f9b8c;
    color: #ffffff;
    fill: #ffffff;
}

.theme_8 .step-finish_go-to-url {
    background-color: #5f9b8c;
    border-color: transparent;
    color: #ffffff;
}

.theme_8 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_8 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_8 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_9 {
    background: #f3e1e1;
}

.constructor-body_slide.theme_9 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_9 .progresbar_line {
    background: #de446a;
}

.theme_9 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_9 .quiz_nav .step_forvard {
    background-color: #de446a;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_9 .ps--active-x>.ps__rail-x,
.theme_9 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_9 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_9 .footer-links a span {
    color: #808080;
}

.theme_9 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_9 .slide_title,
.theme_9 .ol,
.theme_9 .ul,
.theme_9 li,
.theme_9 h1,
.theme_9 h2,
.theme_9 h3,
.theme_9 p {
    color: #000000;
}

.theme_9 .slide_description {
    color: #000000;
}

.step-first-slide.theme_9 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_9 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_9 .bonus-block,
.theme_9 .finish_bonus_wrap .bonus-block,
.theme_9 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, .5);
}

.step-first-slide.theme_9 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_9 .start {
    background: #de446a;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_9 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_9 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_9 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_9 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_9 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_9 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #de446a;
} */
.step-any-slide.theme_9 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #de446a;
}

.step-any-slide.theme_9 .list input:checked+.answer_wrap>div label+.answer-text {
    color: #fff;
}

.step-any-slide.theme_9 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_9 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_9 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_9 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_9 .variant input:checked+.answer_wrap {
    border-color: #de446a;
}

.step-any-slide.theme_9 input:checked+.answer_wrap:after {
    background: #de446a;
}

.step-any-slide.theme_9 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_9 .list input:checked+.answer_wrap,
.step-any-slide.theme_9 .variant input:checked+.answer_wrap {
    border-color: #de446a;
    background-color: #de446a;
}

.step-any-slide.theme_9 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_9 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #de446a;
    background-color: #de446a;
}


.step-any-slide.theme_9 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_9 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_9 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_9 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_9 .answer_wrap:hover,
.theme_9 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #de446a;
}

.theme_9 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_9 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_9 .slide_form_inputs .slide_form_input,
.theme_9 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_9 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_9 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #de446a;
    color: #ffffff;
    fill: #ffffff;
}

.theme_9 .step-finish_go-to-url {
    background-color: #de446a;
    border-color: transparent;
    color: #ffffff;
}

.theme_9 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_9 .step-finish-slide .slide_social-links {
    fill: black;
}

.theme_9 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_10 {
    background: #d8d8ef;
}

.constructor-body_slide.theme_10 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_10 .progresbar_line {
    background: #4d4793;
}

.theme_10 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_10 .quiz_nav .step_forvard {
    background-color: #4d4793;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_10 .ps--active-x>.ps__rail-x,
.theme_10 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_10 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_10 .footer-links a span {
    color: #808080;
}

.theme_10 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_10 .slide_title,
.theme_10 .ol,
.theme_10 .ul,
.theme_10 li,
.theme_10 h1,
.theme_10 h2,
.theme_10 h3,
.theme_10 p {
    color: #000000;
}

.theme_10 .slide_description {
    color: #000000;
}

.step-first-slide.theme_10 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_10 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_10 .bonus-block,
.theme_10 .finish_bonus_wrap .bonus-block,
.theme_10 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(245, 245, 245, .5);
}

.step-first-slide.theme_10 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_10 .start {
    background: #4d4793;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_10 .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_10 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_10 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_10 .answer_wrap .answer_radio .variant_checker:after {
    background: #cccccc;
}

.step-any-slide.theme_10 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_10 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #4d4793;
} */
.step-any-slide.theme_10 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #4d4793;
}

.step-any-slide.theme_10 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_10 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_10 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_10 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_10 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_10 .variant input:checked+.answer_wrap {
    border-color: #4d4793;
}

.step-any-slide.theme_10 input:checked+.answer_wrap:after {
    background: #4d4793;
}

.step-any-slide.theme_10 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_10 .list input:checked+.answer_wrap {
    border-color: #fff;
    background-color: #4d4793;
}

.step-any-slide.theme_10 .variant input:checked+.answer_wrap {
    border-color: #4d4793;
    background-color: #4d4793;
}

.step-any-slide.theme_10 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_10 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #4d4793;
    background-color: #4d4793;
}


.step-any-slide.theme_10 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_10 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_10 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_10 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_10 .answer_wrap:hover,
.theme_10 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #4d4793;
}

.theme_10 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_10 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_10 .slide_form_inputs .slide_form_input,
.theme_10 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_10 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_10 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #4d4793;
    color: #ffffff;
    fill: #ffffff;
}

.theme_10 .step-finish_go-to-url {
    background-color: #4d4793;
    border-color: transparent;
    color: #ffffff;
}

.theme_10 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_lide_social.slide_social-links path {
    fill: black;
}

.theme_10 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_11 {
    background: #d6e4f1;
}

.constructor-body_slide.theme_11 .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_11 .progresbar_line {
    background: #095eac;
}

.theme_11 .quiz_nav .step_back svg path{
    color: #a4a4a4;
    fill: #a4a4a4;
}

.theme_11 .quiz_nav .step_forvard {
    background-color: #095eac;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_11 .ps--active-x>.ps__rail-x,
.theme_11 .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_11 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_11 .footer-links a span {
    color: #808080;
}

.theme_11 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_11 .slide_title,
.theme_11 .ol,
.theme_11 .ul,
.theme_11 li,
.theme_11 h1,
.theme_11 h2,
.theme_11 h3,
.theme_11 p {
    color: #000000;
}

.theme_11 .slide_description {
    color: #000000;
}

.step-first-slide.theme_11 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_11 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_11 .bonus-block,
.theme_11 .finish_bonus_wrap .bonus-block,
.theme_11 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(245, 245, 245, .5);
}

.step-first-slide.theme_11 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_11 .start {
    background: #095eac;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_11 .list .answer_wrap {
    border-color: #000;
}

.step-any-slide.theme_11 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_11 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #000;
}

.step-any-slide.theme_11 .answer_wrap .answer_radio .variant_checker:after {
    background: #000;
}

.step-any-slide.theme_11 .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_11 .list input:checked + .answer_wrap {
  background-color: transparent;
  border-color: #095eac;
} */
.step-any-slide.theme_11 .answer_wrap .answer_radio .variant_checker:after {
    border-color: #095eac;
}

.step-any-slide.theme_11 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_11 .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_11 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_11 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_11 .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_11 .variant input:checked+.answer_wrap {
    border-color: #095eac;
}

.step-any-slide.theme_11 input:checked+.answer_wrap:after {
    background: #095eac;
}

.step-any-slide.theme_11 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_11 .list input:checked+.answer_wrap {
    border-color: #fff;
    background-color: #095eac;
}

.step-any-slide.theme_11 .variant input:checked+.answer_wrap {
    border-color: #095eac;
    background-color: #095eac;
}

.step-any-slide.theme_11 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_11 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #095eac;
    background-color: #095eac;
}


.step-any-slide.theme_11 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_11 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_11 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_11 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_11 .answer_wrap:hover,
.theme_11 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #095eac;
}

.theme_11 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: #f5f5f5;
}

.theme_11 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_11 .slide_form_inputs .slide_form_input,
.theme_11 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f5f5f5;
}

.theme_11 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_11 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #095eac;
    color: #ffffff;
    fill: #ffffff;
}

.theme_11 .step-finish_go-to-url {
    background-color: #095eac;
    border-color: transparent;
    color: #ffffff;
}

.theme_11 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_lide_social.slide_social-links path {
    fill: black;
}

.theme_11 input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}

.constructor-body_slide.theme_12 {
    background: linear-gradient(48deg, #1d2671 0%, #c33764 100%);
}

.theme_12 .quiz-body_blur {
    filter: blur(1px);
}

.constructor-body_slide.theme_12 .slide_progresbar {
    background: rgba(255, 255, 255, 0.2);
}

.constructor-body_slide.theme_12 .progresbar_line {
    background: rgba(255, 255, 255, 0.5);
}

.theme_12 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_12 .quiz_nav .step_forvard {
    background-color: #fff;
    border-color: #fff;
    fill: #1e2029;
    color: #1e2029;
}

.theme_12 .ps--active-x>.ps__rail-x,
.theme_12 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_12 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_12 .footer-links a span {
    color: #808080;
}

.theme_12 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_12 .slide_title,
.theme_12 .ol,
.theme_12 .ul,
.theme_12 li,
.theme_12 h1,
.theme_12 h2,
.theme_12 h3,
.theme_12 p {
    color: #ffffff;
}

.theme_12 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_12 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_12 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_12 .bonus-block,
.theme_12 .finish_bonus_wrap .bonus-block,
.theme_12 .quiz_slides_benefits .benefit_wrap {
    border-color: transparent;
    background: rgba(255, 255, 255, .1);
}

.step-first-slide.theme_12 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_12 .start {
    background: #fff;
    border-color: #fff;
    color: #1E2029;
}

.step-any-slide.theme_12 .list .answer_wrap {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_12 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_12 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_12 .answer_wrap .answer_radio .variant_checker:after {
    background: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_12 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_12 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_12 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_12 .list input:checked+.answer_wrap>div label+label {
    color: #1e2029;
}

.step-any-slide.theme_12 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.2); */
    border-color: transparent;
}

.step-any-slide.theme_12 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_12 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.2);
}

.step-any-slide.theme_12 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_12 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_12 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_12 input:checked+.answer_wrap svg path {
    stroke: #1e2029;
}

.step-any-slide.theme_12 .list input:checked+.answer_wrap{
    border-color: #1E2029;
    background-color: #f5f5f5;
}

.step-any-slide.theme_12 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}

.step-any-slide.theme_12 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_12 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}


.step-any-slide.theme_12 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_12 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #1e2029;
}

.step-any-slide.theme_12 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #1e2029;
}

.step-any-slide.theme_12 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #1e2029;
}

.theme_12 .answer_wrap:hover,
.theme_12 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #1e2029;
}

.theme_12 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_12 .answer_blank_input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .72);
}

.theme_12 .slide_form_inputs .slide_form_input,
.theme_12 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_12 .slide_form_inputs .slide_form_input::placeholder {
    color: rgba(255, 255, 255, .72);
}

.theme_12 .quiz_nav .step_send {
    border-color: #fff;
    background-color: #fff;
    color: #1e2029;
    fill: #1e2029;
}

.theme_12 .step-finish_go-to-url {
    background-color: #fff;
    border-color: #fff;
    color: #1e2029;
}

.theme_12 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_lide_social.slide_social-links path {
    fill: white;
}

.theme_12 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_13 {
    background: linear-gradient(16deg, #869AAF 4.39%, #dbe2e6 107.44%);
}

.theme_13 .quiz-body_blur {
    filter: blur(1px);
}

.constructor-body_slide.theme_13 .slide_progresbar {
    background: rgba(255, 255, 255, 0.2);
}

.constructor-body_slide.theme_13 .progresbar_line {
    background: #2f3e4d;
}

.theme_13 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_13 .quiz_nav .step_forvard {
    background-color: #2f3e4d;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_13 .ps--active-x>.ps__rail-x,
.theme_13 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_13 .footer-links a {
    color: #2f3e4d;
}

.constructor-body_slide.theme_13 .footer-links a span {
    color: #2f3e4d;
}

.theme_13 .slide_footer .footer-links a+a {
    color: #2f3e4d;
}

.theme_13 .slide_title,
.theme_13 .ol,
.theme_13 .ul,
.theme_13 li,
.theme_13 h1,
.theme_13 h2,
.theme_13 h3,
.theme_13 p {
    color: #000000;
}

.theme_13 .slide_description {
    color: #000000;
}

.step-first-slide.theme_13 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_13 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_13 .bonus-block,
.theme_13 .finish_bonus_wrap .bonus-block,
.theme_13 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, .24);
}

.step-first-slide.theme_13 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_13 .start {
    background: #2f3e4d;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_13 .list .answer_wrap {
    border-color: #2F3E4D;
}

.step-any-slide.theme_13 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_13 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #2F3E4D;
}

.step-any-slide.theme_13 .answer_wrap .answer_radio .variant_checker:after {
    background: #2F3E4D;
}

.step-any-slide.theme_13 .answer_wrap>div label+.answer-text {
    color: #2F3E4D;
}

.step-any-slide.theme_13 .answer_wrap>div label+.answer-text p {
    color: #2F3E4D;
}

/* .step-any-slide.theme_13 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_13 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_13 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_13 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.24); */
    border-color: transparent;
}

.step-any-slide.theme_13 .variant .answer_wrap>div {
    border-color: rgba(255, 255, 255, 0.24);
    background-color: rgba(255, 255, 255, 0.24);
    
}

.step-any-slide.theme_13 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_13 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.2);
}

.step-any-slide.theme_13 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_13 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_13 .variant input:checked+.answer_wrap>div {
    border-color: #2F3E4D;
    background-color: transparent;
}

.step-any-slide.theme_13 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_13 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_13 .list input:checked+.answer_wrap,
.step-any-slide.theme_13 .variant input:checked+.answer_wrap {
    border-color: #2f3e4d;
    background-color: #2f3e4d;
}

.step-any-slide.theme_13 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_13 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #2f3e4d;
    background-color: #2f3e4d;
}


.step-any-slide.theme_13 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_13 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_13 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_13 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_13 .answer_wrap:hover,
.theme_13 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #2f3e4d;
}

.theme_13 .answer_blank_input[type="text"] {
    border-color: rgba(255, 255, 255, .32);
    background: rgba(255, 255, 255, .24);
}

.theme_13 .answer_blank_input[type="text"]::placeholder {
    color: rgba(47, 62, 77, .48);
}

.theme_13 .slide_form_inputs .slide_form_input,
.theme_13 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: rgba(255, 255, 255, .32);
    background: rgba(255, 255, 255, .24);
}

.theme_13 .slide_form_inputs .slide_form_input::placeholder {
    color: rgba(47, 62, 77, .48);
}

.theme_13 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #2f3e4d;
    color: #ffffff;
    fill: #ffffff;
}

.theme_13 .step-finish_go-to-url {
    background-color: #2f3e4d;
    border-color: transparent;
    color: #ffffff;
}

.theme_13 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_lide_social.slide_social-links path {
    fill: black;
}

.theme_13 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_14 {
    background: linear-gradient(134.28deg, #74ebd5 -5.32%, #acb6e5 74.98%);
}

.theme_14 .quiz-body_blur {
    filter: blur(1px);
}

.constructor-body_slide.theme_14 .slide_progresbar {
    background: rgba(255, 255, 255, 0.3);
}

.constructor-body_slide.theme_14 .progresbar_line {
    background: #000000;
}

.theme_14 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_14 .quiz_nav .step_forvard {
    background-color: #000000;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_14 .ps--active-x>.ps__rail-x,
.theme_14 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.3);
}

.constructor-body_slide.theme_14 .footer-links a {
    color: #2f3e4d;
}

.constructor-body_slide.theme_14 .footer-links a span {
    color: #2f3e4d;
}

.theme_14 .slide_footer .footer-links a+a {
    color: #2f3e4d;
}

.theme_14 .slide_title,
.theme_14 .ol,
.theme_14 .ul,
.theme_14 li,
.theme_14 h1,
.theme_14 h2,
.theme_14 h3,
.theme_14 p {
    color: #000000;
}

.theme_14 .slide_description {
    color: #000000;
}

.step-first-slide.theme_14 .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_14 .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_14 .bonus-block,
.theme_14 .finish_bonus_wrap .bonus-block,
.theme_14 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, 0.3);
}

.step-first-slide.theme_14 .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_14 .start {
    background: #000000;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_14 .list .answer_wrap {
    border-color: #000;
}

.step-any-slide.theme_14 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_14 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #000;
}

.step-any-slide.theme_14 .answer_wrap .answer_radio .variant_checker:after {
    background: #000;
}

.step-any-slide.theme_14 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_14 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_14 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_14 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_14 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.5); */
    border-color: transparent;
}

.step-any-slide.theme_14 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_14 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_14 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_14 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_14 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_14 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_14 .list input:checked+.answer_wrap,
.step-any-slide.theme_14 .variant input:checked+.answer_wrap {
    border-color: #000;
    background-color: #000;
}

.step-any-slide.theme_14 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_14 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #000;
    background-color: #000;
}


.step-any-slide.theme_14 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_14 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_14 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_14 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_14 .answer_wrap:hover,
.theme_14 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #000000;
}

.theme_14 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(255, 255, 255, .44);
}

.theme_14 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_14 .slide_form_inputs .slide_form_input,
.theme_14 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(255, 255, 255, .44);
}

.theme_14 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_14 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #000000;
    color: #ffffff;
    fill: #ffffff;
}

.theme_14 .step-finish_go-to-url {
    background-color: #000000;
    border-color: transparent;
    color: #ffffff;
}

.theme_14 .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_lide_social.slide_social-links path {
    fill: black;
}

.theme_14 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_15 {
    background: linear-gradient(137.77deg,
            #0f2027 3.59%,
            #203a43 47.53%,
            #2c5364 95.62%);
}

.theme_15 .quiz-body_blur {
    filter: blur(1px);
}

.constructor-body_slide.theme_15 .slide_progresbar {
    background: rgba(255, 255, 255, 0.2);
}

.constructor-body_slide.theme_15 .progresbar_line {
    background: #163440;
}

.theme_15 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_15 .quiz_nav .step_forvard {
    background-color: #577E8E;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_15 .ps--active-x>.ps__rail-x,
.theme_15 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_15 .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_15 .footer-links a span {
    color: #808080;
}

.theme_15 .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_15 .slide_title,
.theme_15 .ol,
.theme_15 .ul,
.theme_15 li,
.theme_15 h1,
.theme_15 h2,
.theme_15 h3,
.theme_15 p {
    color: #ffffff;
}

.theme_15 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_15 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_15 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_15 .bonus-block,
.theme_15 .finish_bonus_wrap .bonus-block,
.theme_15 .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, .1);
}

.step-first-slide.theme_15 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_15 .start {
    background: #577E8E;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_15 .list .answer_wrap {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_15 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_15 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_15 .answer_wrap .answer_radio .variant_checker:after {
    background: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_15 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_15 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_15 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_15 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_15 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.2); */
    border-color: transparent;
}

.step-any-slide.theme_15 .variant .answer_wrap>div {
    background-color: rgba(255, 255, 255, 0.1);
}

.step-any-slide.theme_15 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_15 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.2);
}

.step-any-slide.theme_15 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_15 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_15 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_15 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_15 .list input:checked+.answer_wrap,
.step-any-slide.theme_15 .variant input:checked+.answer_wrap {
    border-color: #fff;
    background-color: #163440;
}

.step-any-slide.theme_15 .variant .answer_wrap>div {
    background-color: transparent;
}

.step-any-slide.theme_15 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_15 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #163440;
    background-color: #163440;
}


.step-any-slide.theme_15 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_15 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_15 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_15 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_15 .answer_wrap:hover,
.theme_15 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #163440;
}

.theme_15 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_15 .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_15 .slide_form_inputs .slide_form_input,
.theme_15 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_15 .slide_form_inputs .slide_form_input::placeholder {
    color: #66757f;
}

.theme_15 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #163440;
    color: #ffffff;
    fill: #ffffff;
}

.theme_15 .step-finish_go-to-url {
    background-color: #163440;
    border-color: transparent;
    color: #ffffff;
}

.theme_15 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_lide_social.slide_social-links path {
    fill: white;
}

.theme_15 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_16 {
    background: linear-gradient(163.33deg, #2b5876 0.71%, #4e4376 99.15%);
}

.theme_16 .quiz-body_blur {
    filter: blur(44682px);
}

.constructor-body_slide.theme_16 .slide_progresbar {
    background: rgba(255, 255, 255, 0.2);
}

.constructor-body_slide.theme_16 .progresbar_line {
    background: #6056a0;
}

.theme_16 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_16 .quiz_nav .step_forvard {
    background-color: #6056a0;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_16 .ps--active-x>.ps__rail-x,
.theme_16 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_16 .footer-links a {
    color: #fbfbfb;
}

.constructor-body_slide.theme_16 .footer-links a span {
    color: #fbfbfb;
}

.theme_16 .slide_footer .footer-links a+a {
    color: #fbfbfb;
}

.theme_16 .slide_title,
.theme_16 .ol,
.theme_16 .ul,
.theme_16 li,
.theme_16 h1,
.theme_16 h2,
.theme_16 h3,
.theme_16 p {
    color: #ffffff;
}

.theme_16 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_16 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_16 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_16 .bonus-block,
.theme_16 .finish_bonus_wrap .bonus-block,
.theme_16 .quiz_slides_benefits .benefit_wrap {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.1);
}

.step-first-slide.theme_16 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_16 .start {
    background: #6056a0;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_16 .list .answer_wrap {
    border-color: rgba(255, 255, 255, 1);
}

.step-any-slide.theme_16 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_16 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 1);
}

.step-any-slide.theme_16 .answer_wrap .answer_radio .variant_checker:after {
    background: rgba(255, 255, 255, 1);
}

.step-any-slide.theme_16 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_16 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_16 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_16 .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_16 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.2); */
    border-color: transparent;
}

.step-any-slide.theme_16 .variant .answer_wrap>div {
    background-color: rgba(255, 255, 255, 0.1);
}

.step-any-slide.theme_16 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_16 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.2);
}

.step-any-slide.theme_16 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_16 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_16 .variant input:checked+.answer_wrap>div {
    background-color: transparent;
}

.step-any-slide.theme_16 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_16 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_16 .list input:checked+.answer_wrap,
.step-any-slide.theme_16 .variant input:checked+.answer_wrap {
    border-color: #6056a0;
    background-color: #6056a0;
}

.step-any-slide.theme_16 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_16 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #6056a0;
    background-color: #6056a0;
}


.step-any-slide.theme_16 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_16 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_16 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_16 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_16 .answer_wrap:hover,
.theme_16 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #6056a0;
}

.theme_16 .answer_blank_input[type="text"] {
    border-color: transparent;
    background: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_16 .answer_blank_input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .72);
}

.theme_16 .slide_form_inputs .slide_form_input,
.theme_16 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .12);
    color: #fff;
}

.theme_16 .slide_form_inputs .slide_form_input::placeholder {
    color: rgba(255, 255, 255, .72);
}

.theme_16 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #6056a0;
    color: #ffffff;
    fill: #ffffff;
}

.theme_16 .step-finish_go-to-url {
    background-color: #6056a0;
    border-color: transparent;
    color: #ffffff;
}

.theme_16 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_lide_social.slide_social-links path {
    fill: white;
}

.theme_16 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_17 {
    background: radial-gradient(127.88% 140.96% at 73.44% -18.94%,
            #0f1336 0%,
            #282a3d 36.55%,
            #262730 100%);
}

.theme_17 .quiz-body_blur {
    filter: blur(44682px);
}

.constructor-body_slide.theme_17 .slide_progresbar {
    background: rgba(255, 255, 255, 0.2);
}

.constructor-body_slide.theme_17 .progresbar_line {
    background: rgba(255, 255, 255, 0.5);
}

.theme_17 .quiz_nav .step_back svg path{
    color: rgba(255, 255, 255, 0.3);
    fill: rgba(255, 255, 255, 0.3);
}

.theme_17 .quiz_nav .step_forvard {
    background-color: #1e2029;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_17 .ps--active-x>.ps__rail-x,
.theme_17 .ps--active-y>.ps__rail-y {
    background-color: rgba(255, 255, 255, 0.1);
}

.constructor-body_slide.theme_17 .footer-links a {
    color: #f2f2f2;
}

.constructor-body_slide.theme_17 .footer-links a span {
    color: #f2f2f2;
}

.theme_17 .slide_footer .footer-links a+a {
    color: #f2f2f2;
}

.theme_17 .slide_title,
.theme_17 .ol,
.theme_17 .ul,
.theme_17 li,
.theme_17 h1,
.theme_17 h2,
.theme_17 h3,
.theme_17 p {
    color: #ffffff;
}

.theme_17 .slide_description {
    color: #ffffff;
}

.step-first-slide.theme_17 .block_info h1 {
    color: #ffffff;
}

.step-first-slide.theme_17 .block_info_body>p {
    color: #ffffff;
}

.step-first-slide.theme_17 .bonus-block,
.theme_17 .finish_bonus_wrap .bonus-block,
.theme_17 .quiz_slides_benefits .benefit_wrap {
    border-color: transparent;
    background: rgba(255, 255, 255, 0.1);
}

.step-first-slide.theme_17 .bonus-block p {
    color: #ffffff;
}

.step-first-slide.theme_17 .start {
    background: #1e2029;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_17 .list .answer_wrap {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_17 .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_17 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_17 .answer_wrap .answer_radio .variant_checker:after {
    background: rgba(255, 255, 255, 0.5);
}

.step-any-slide.theme_17 .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

/* .step-any-slide.theme_17 .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_17 .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_17 .list input:checked+.answer_wrap>div label+label {
    color: #1e2029;
}

.step-any-slide.theme_17 .variant .answer_wrap {
    /* border-color: rgba(255, 255, 255, 0.2); */
    border-color: transparent;
}

.step-any-slide.theme_17 .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_17 .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(255, 255, 255, 0.2);
}

.step-any-slide.theme_17 .variant .answer_wrap>div label+.answer-text {
    color: #ffffff;
}

.step-any-slide.theme_17 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_17 input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_17 input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_17 .list input:checked+.answer_wrap,
.step-any-slide.theme_17 .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}

.step-any-slide.theme_17 .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_17 .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #f5f5f5;
    background-color: #f5f5f5;
}


.step-any-slide.theme_17 .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_17 .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #1e2029;
}

.step-any-slide.theme_17 .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #1e2029;
}

.step-any-slide.theme_17 .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #1e2029;
}

.theme_17 .answer_wrap:hover,
.theme_17 .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #1e2029;
}

.theme_17 .answer_blank_input[type="text"] {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .16);
    color: #fff;
}

.theme_17 .answer_blank_input[type="text"]::placeholder {
    color: rgba(255, 255, 255, .48);
}

.theme_17 .slide_form_inputs .slide_form_input,
.theme_17 .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: rgba(245, 245, 245, .16);
}

.theme_17 .slide_form_inputs .slide_form_input::placeholder {
    color: rgba(255, 255, 255, .48);
}

.theme_17 .quiz_nav .step_send {
    border-color: transparent;
    background-color: #1e2029;
    color: #ffffff;
    fill: #ffffff;
}

.theme_17 .step-finish_go-to-url {
    background-color: #1e2029;
    border-color: transparent;
    color: #ffffff;
}

.theme_17 .slide_social-links a {
    color: #ffffff;
    fill: #ffffff;
}

.theme_lide_social.slide_social-links path {
    fill: white;
}

.theme_17 input.answer-text.custom-answer::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.constructor-body_slide.theme_1_white {
    background: #ffffff;
}

.constructor-body_slide.theme_1_white .slide_progresbar {
    background: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_1_white .progresbar_line {
    background: #324ac7;
}

.theme_1_white .quiz_nav .step_back svg path{
    color: rgba(0, 0, 0, 0.3);
    fill: rgba(0, 0, 0, 0.3);
}

.theme_1_white .quiz_nav .step_forvard {
    background-color: #324ac7;
    border-color: transparent;
    fill: #ffffff;
    color: #ffffff;
}

.theme_1_white .ps--active-x>.ps__rail-x,
.theme_1_white .ps--active-y>.ps__rail-y {
    background-color: rgba(0, 0, 0, 0.1);
}

.constructor-body_slide.theme_1_white .footer-links a {
    color: #808080;
}

.constructor-body_slide.theme_1_white .footer-links a span {
    color: #808080;
}

.theme_1_white .slide_footer .footer-links a+a {
    color: #808080;
}

.theme_1_white .slide_title,
.theme_1_white .ol,
.theme_1_white .ul,
.theme_1_white li,
.theme_1_white h1,
.theme_1_white h2,
.theme_1_white h3,
.theme_1_white p {
    color: #000000;
}

.theme_1_white .slide_description {
    color: #000000;
}

.step-first-slide.theme_1_white .block_info h1 {
    color: #000000;
}

.step-first-slide.theme_1_white .block_info_body>p {
    color: #000000;
}

.step-first-slide.theme_1_white .bonus-block,
.theme_1_white .finish_bonus_wrap .bonus-block,
.theme_1_white .quiz_slides_benefits .benefit_wrap {
    border-color: rgba(0, 0, 0, 0.1);
    background: rgba(50, 74, 199, 0.08);
}

.step-first-slide.theme_1_white .bonus-block p {
    color: #000000;
}

.step-first-slide.theme_1_white .start {
    background: #324ac7;
    border-color: transparent;
    color: #ffffff;
}

.step-any-slide.theme_1_white .list .answer_wrap {
    border-color: #cccccc;
}

.step-any-slide.theme_1_white .list .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1_white .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: #cccccc;
}

.step-any-slide.theme_1_white .answer_wrap .answer_radio .variant_checker:after {
    border-color: #cccccc;
}

.step-any-slide.theme_1_white .answer_wrap>div label+.answer-text {
    color: #000000;
}

/* .step-any-slide.theme_1_white .list input:checked + .answer_wrap {
  background-color: #f5f5f5;
  border-color: transparent;
} */
.step-any-slide.theme_1_white .answer_wrap .answer_radio .variant_checker:after {
    border-color: transparent;
}

.step-any-slide.theme_1_white .list input:checked+.answer_wrap>div label+label {
    color: #fff;
}

.step-any-slide.theme_1_white .variant .answer_wrap {
    /* border-color: rgba(0, 0, 0, 0.1); */
    border-color: transparent;
}

.step-any-slide.theme_1_white .variant .answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1_white .variant .answer_wrap .answer_checkbox .variant_checker {
    border-color: rgba(0, 0, 0, 0.1);
}

.step-any-slide.theme_1_white .variant .answer_wrap>div label+.answer-text {
    color: #000000;
}

.step-any-slide.theme_1_white .variant input:checked+.answer_wrap {
    border-color: #f5f5f5;
}

.step-any-slide.theme_1_white input:checked+.answer_wrap:after {
    background: #f5f5f5;
}

.step-any-slide.theme_1_white input:checked+.answer_wrap svg path {
    stroke: #f5f5f5;
}

.step-any-slide.theme_1_white .list input:checked+.answer_wrap,
.step-any-slide.theme_1_white .variant input:checked+.answer_wrap {
    border-color: #324ac7;
    background-color: #324ac7;
}

.step-any-slide.theme_1_white .variant input:checked+.answer_wrap .answer_radio .variant_checker,
.step-any-slide.theme_1_white .variant input:checked+.answer_wrap .answer_checkbox .variant_checker {
    border-color: #324ac7;
    background-color: #324ac7;
}


.step-any-slide.theme_1_white .slide_answers input:checked+.answer_wrap>div label+.answer-text,
.step-any-slide.theme_1_white .slide_answers input:checked+.answer_wrap>div label+label p {
    color: #fff;
}

.step-any-slide.theme_1_white .list input:checked+.answer_wrap>div .variant_checker {
    border-color: #fff;
}

.step-any-slide.theme_1_white .list input:checked+.answer_wrap>div .variant_checker:after {
    background-color: #fff;
}

.theme_1_white .answer_wrap:hover,
.theme_1_white .answer_wrap:focus {
    box-shadow: 1px 3px 5px 0px #324ac7;
}

.theme_1_white .answer_blank_input[type="text"] {
    border-color: #D9D9D9;
    background: #f5f5f5;
}

.theme_1_white .answer_blank_input[type="text"]::placeholder {
    color: #66757f;
}

.theme_1_white .slide_form_inputs .slide_form_input,
.theme_1_white .slide_form_inputs .slide_form_input .vue-tel-input {
    border-color: transparent;
    background-color: #f2f2f2;
}

.theme_1_white .slide_form_inputs .slide_form_input::placeholder {
    color: #808080;
}

.theme_1_white .quiz_nav .step_send {
    border-color: transparent;
    background-color: #324ac7;
    color: #ffffff;
    fill: #ffffff;
}

.theme_1_white .step-finish_go-to-url {
    background-color: #324ac7;
    border-color: transparent;
    color: #ffffff;
}

.theme_1_white .slide_social-links a {
    color: #000000;
    fill: #000000;
}

.theme_1_white .slide_social-links path {
    fill: black;
}

.theme_1_white input.answer-text.custom-answer::placeholder {
    color: rgba(0, 0, 0, 0.6)
}







/* ================ */

input.answer-text.custom-answer:focus::placeholder {
    color: transparent !important;
}

.theme_1.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_2.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_3.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_4.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_5.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_6.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_7.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_8.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_9.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_10.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_11.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_12.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_13.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_14.step-first-slide .bonus-block svg path {
    fill: #000000;
}

.theme_15.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_16.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_17.step-first-slide .bonus-block svg path {
    fill: #ffffff;
}

.theme_1_white.step-first-slide .bonus-block svg path {
    fill: #000000;
}


@media (hover: hover) {
    .theme_1 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #324ac7;
    }

    .theme_2 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #9d324c;
    }

    .theme_3 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #463cb5;
    }

    .theme_4 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #1c8f6a;
    }

    .theme_5 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #21252a;
    }

    .theme_6 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #6f21d8;
    }

    .theme_7 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #006052;
    }

    .theme_8 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #5f9b8c;
    }

    .theme_9 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #de446a;
    }

    .theme_10 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #4d4793;
    }

    .theme_11 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #095eac;
    }

    .theme_12 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #1e2029;
    }

    .theme_13 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #2f3e4d;
    }

    .theme_14 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #000000;
    }

    .theme_15 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #163440;
    }

    .theme_16 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #6056a0;
    }

    .theme_17 .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #1e2029;
    }

    .theme_1_white .answer_wrap:hover {
        box-shadow: 1px 3px 5px 0px #324ac7;
    }
}
/* ======================= */


@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }
    10% {
        background-position: 25% 75%;
        opacity: 0.8;
    }
    20% {
        background-position: 50% 25%;
        opacity: 0.6;
    }
    30% {
        background-position: 75% 50%;
        opacity: 0.9;
    }
    40% {
        background-position: 100% 75%;
        opacity: 0.7;
    }
    50% {
        background-position: 75% 25%;
        opacity: 0.5;
    }
    60% {
        background-position: 50% 50%;
        opacity: 0.9;
    }
    70% {
        background-position: 25% 75%;
        opacity: 0.8;
    }
    80% {
        background-position: 50% 25%;
        opacity: 0.6;
    }
    90% {
        background-position: 25% 50%;
        opacity: 0.7;
    }
    100% {
        background-position: 0% 50%;
        opacity: 0.9;
    }
}
/* =================== */

.ql-editor {
    transition: all 0s ease!important;;
}
.ql-editor * {
    transition: all 0s ease!important;;
}
/* .empty_page ol,
.empty_page ul {
    padding-inline-start: 0;
}
.empty_page li {
    padding-inline-start: 40px;
} */
.empty_page li[data-list="bullet"] {
    list-style-type: disc;
}

.empty_page .ql-size-small {
    font-size: 0.75em 
}
.empty_page .ql-size-large {
    font-size: 1.5em 
}
.empty_page .ql-size-huge {
    font-size: 2.5em 
}
.empty_page .ql-direction-rtl {
    direction: rtl;
    text-align: inherit 
}
.empty_page .ql-align-center {
    text-align: center 
}
.empty_page .ql-align-justify {
    text-align: justify 
}
.empty_page .ql-align-right {
    text-align: right
}


/* ================ */

.finish_bonus_wrap .bonus-block.pereliv-label {
    background-size: 1500px 1500px;
    animation: gradientShift 25s alternate ease-in-out infinite; /* Более плавная анимация */
}

/* Асинхронная анимация для двух бенефитов */
.finish_bonus_wrap .bonus-block.pereliv-label.benefit_0 {
    animation-delay: 0s;
}

.finish_bonus_wrap .bonus-block.pereliv-label.benefit_1 {
    animation-delay: -12s;
}

.theme_default .finish_bonus_wrap .bonus-block.pereliv-label,
.theme_1 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(50, 74, 199, 0.15), rgba(255, 255, 255, 0.5), rgba(50, 74, 199, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_2 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(157, 50, 76, 0.15), rgba(255, 255, 255, 0.5), rgba(157, 50, 76, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_3 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(70, 60, 181, 0.15), rgba(255, 255, 255, 0.5), rgba(70, 60, 181, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_4 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(28, 143, 106, 0.15), rgba(255, 255, 255, 0.5), rgba(28, 143, 106, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_5 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(239, 245, 250, 0.15), rgba(47, 53, 61, 0.5), rgba(239, 245, 250, 0.3), rgba(47, 53, 61, 0.5));
    background-size: 1500px 1500px;
}

.theme_6 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(111, 33, 216, 0.15), rgba(13, 40, 52, 0.5), rgba(111, 33, 216, 0.3), rgba(13, 40, 52, 0.5));
    background-size: 1500px 1500px;
}

.theme_7 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(255, 255, 255, 0.15), rgba(1, 61, 61, 0.5), rgba(255, 255, 255, 0.3), rgba(1, 61, 61, 0.5));
    background-size: 1500px 1500px;
}

.theme_8 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(95, 155, 140, 0.15), rgba(218, 239, 229, 0.5), rgba(95, 155, 140, 0.3), rgba(218, 239, 229, 0.5));
    background-size: 1500px 1500px;
}

.theme_9 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(222, 68, 106, 0.15), rgba(255, 255, 255, 0.5), rgba(222, 68, 106, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_10 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(77, 71, 147, 0.15), rgba(255, 255, 255, 0.5), rgba(77, 71, 147, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_11 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(9, 94, 172, 0.15), rgba(255, 255, 255, 0.5), rgba(9, 94, 172, 0.3), rgba(255, 255, 255, 0.5));
    background-size: 1500px 1500px;
}

.theme_12 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.1));
    background-size: 1500px 1500px;
}

.theme_13 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(47, 62, 77, 0.15), rgba(255, 255, 255, 0.24), rgba(47, 62, 77, 0.3), rgba(255, 255, 255, 0.24));
    background-size: 1500px 1500px;
}

.theme_14 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(0, 0, 0, 0.15), rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3), rgba(255, 255, 255, 0.3));
    background-size: 1500px 1500px;
}

.theme_15 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(87, 126, 142, 0.15), rgba(255, 255, 255, 0.1), rgba(87, 126, 142, 0.3), rgba(255, 255, 255, 0.1));
    background-size: 1500px 1500px;
}

.theme_16 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(96, 86, 160, 0.15), rgba(255, 255, 255, 0.1), rgba(96, 86, 160, 0.3), rgba(255, 255, 255, 0.1));
    background-size: 1500px 1500px;
}

.theme_17 .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(30, 32, 41, 0.1), rgba(255, 255, 255, 0.1), rgba(30, 32, 41, 0.1), rgba(255, 255, 255, 0.1));
    background-size: 1500px 1500px;
}

.theme_1_white .finish_bonus_wrap .bonus-block.pereliv-label {
    background: radial-gradient(rgba(50, 74, 199, 0.15), rgba(50, 74, 199, 0.08), rgba(50, 74, 199, 0.3), rgba(50, 74, 199, 0.08));
    background-size: 1500px 1500px;
}



.slide_form_inputs .slide_form_input.vue-tel-input_wrapper {
    background: transparent;
    border: none;
}

        /* ========RTL=========== */
    
        .rtl.step-first-slide .bonus-block img {
            margin-right: 0;
            margin-left: 8px;
        }
        
        .rtl .quiz_slides_benefits {
            left: auto;
            right: 0;
        }
        
        .rtl .benefits_full_opener:checked + .quiz_slides_benefits .benefit_wrap p,
        .rtl .benefits_full_opener:checked + .quiz_slides_benefits .bonus-block p {
            margin-left: 0;
            margin-right: 12px;
        }

        .rtl .benefits_questions_wrap {
            left: auto;
            right: 0;
        }

        .rtl.step-finish-slide .block_img + .block_info {
            align-items: flex-start;
        }

        .rtl.step-finish-slide .block_img + .block_info .slide_body {
            align-items: flex-start;
        }
        
        .rtl.step-finish-slide_wrap .block_img + .block_info .slide_title {
            text-align: right;
        }
        
        .rtl.step-finish-slide_wrap .block_img + .block_info .slide_description {
            text-align: right;
        }

        /* .rtl.step-any-slide .slide_answers_list .answer_wrap > div {
            flex-direction: row-reverse;
        } */
    
        .rtl.step-any-slide .slide_answers.list .answer_wrap > div label + .answer-text {
            padding-left: 0;
            padding-right: 8px;
        }
    
        .rtl .quiz_nav .step_back {
            margin-right: 0;
            margin-left: 20px;
        }

        .rtl .step_forvard svg,
        .rtl .step_back svg {
            transform: rotate(180deg);
        }

        .rtl .quiz_nav .step_send svg {
            transform: rotate(270deg);
            margin-right: 0;
            margin-left: 16px;
        }

        /* ===MOBILE=== */
    
        .rtl.mobile.step-finish-slide_wrap .block_img + .block_info .slide_body {
            align-items: center;
        }
        .rtl.mobile.step-finish-slide_wrap .block_img + .block_info .slide_body {
            align-items: center;
        }
        
        .rtl.mobile.step-finish-slide_wrap .block_img + .block_info .slide_title {
            text-align: center;
        }

        .rtl.mobile .finish_bonus_wrap .bonus-block img {
            margin-right: 0;
            margin-left: 12px;
        }
    
        /* =========RTL============= */



/* ========== RTL =========== */





/* ========== RTL =========== */

@media screen and (min-width: 1600px) {
    .constructor_control {
        width: 460px;
    }
    .constructor_body {
        width: calc(100% - 460px);
    }
}
@media screen and (max-width: 1600px) {
    .constructor_control {
        width: 460px;
    }
    .constructor_body {
        width: calc(100% - 460px);
    }
    h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .step-first-slide .block_info h1 {
        font-size: 28px;
        line-height: 40px;
    }
    .answer_wrap {
        width: calc(33% - 40px);
    }

}


@media screen and (max-width: 1200px) {

    .step-first-slide-format_row .block_info {
        padding: 32px 32px 32px 48px;
    }

    .any-format-slide_wrap .block_info {
        padding: 32px 48px;
    }
    h1 {
        font-size: 28px;
        line-height: 40px; 
    }
    .slide_title {
        font-size: 28px;
        line-height: 40px;
    }

    .quiz_nav a,
    .quiz_nav input[type=button] {
        height: 50px;
        width: 80px;
    }

    .bonus-and-start.two_benefits .bonus-block {
        width: 100%;
    }
    .finish_bonus_wrap.two_benefits .bonus-block {
        width: 100%;
    }
    .mobile .finish_bonus_wrap.two_benefits .bonus-block {
        width: calc(50% - 4px);
    }

    .mobile .bonus-and-start.two_benefits .bonus-block {
        width: calc(50% - 4px);
    }
}

@media screen and (max-width: 720px) {
    .integration_display_head {
        align-items: flex-start;
        flex-direction: column;
        gap: 8px;
    }
}

@media screen and (max-width: 650px) {
    

    .c-ctrl-s_actions {
        opacity: 1;
    }
    .c-ctrl_edit-photo .device_pos_photo {
        flex-wrap: wrap;
        gap: 8px;
    }
    .constructor-board .constructor_control {
        width: calc(100% - 52px);
    }
    .constructor-board .constructor_control>img {
        order: 1;
    }
    .constructor-board .constructor_control .all_pos_photo {
        order: 3;
        width: 100%;
    }
    .constructor-board .constructor_control .img_fit {
        order: 2;
    }
    .constructor-board .constructor_body {
        width: 52px;
        padding: 8px;
        justify-content: space-between;
    }
    .constructor-board#settings-board .constructor_control {
        width: 100%;
        overflow: hidden;
    }
    .constructor-board#settings-board .constructor_body {
        display: none;
        width: 100%;
        position: absolute;
        left: 100%;
        border-radius: 0;
        transition: all 0.2s ease;
        padding: 0;
        align-items: flex-end;
        flex-direction: row-reverse;
        overflow: hidden;
    }
    .constructor-board#settings-board .constructor_body.setting_integration_active_slide_to_left {
        left: 0%;
        display: flex;
    }
    .constructor-board#settings-board .constructor_body.setting_integration_active_extra_popup {
        left: 0%;
        display: flex;
    }
    .constructor_body_settings_content-wrap {
        padding: 8px;
        background-color: #f5f5f5;
        width: 80%;
        z-index: 99;
    }
    .setting_integration_active_overlay {
        display: block;
        position: absolute;
        width: 200%;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
        z-index: 88;
        left: 200%;
        opacity: 0;
        transition: all 0.2s ease;
    }
    .setting_integration_active_slide_to_left .setting_integration_active_overlay {
        left: -100%;
        opacity: 1;
    }
    .some_extra_popup {
        display: flex;
        flex-direction: row-reverse;
        overflow: hidden;
        left: auto;
        right: 0;
    }
    .wrap_scale .some_extra_popup {
        display: flex;
        overflow: visible;
        border-left: 1px #ccc solid;
        min-width: 240px;
    }
    .setting_integration_active_extra_popup .constructor_body_settings_content-wrap {
        width: auto;
    }
    /* .close_all_extra_popup,
    .select_embed_variant {
        left: -100%;
    } */
    .close_all_extra_popup {
        background-color: rgba(0, 0, 0, 0.1);
    }
    .constructor-board .constructor_control.c-ctrl_sub_settings {
        width: 100%;
    }
    .constructor-board .constructor_body.constructor_vidget_body {
        display: none;
    }
    .constructor-body_display-header .pagination-container {
        display: none!important;
    }
    .constructor-body_display-header_action {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }
    .adaptive_button_wrap, .hide_delete_step_button_wrap {
        display: flex;
        flex-direction: column;
    }
    .constructor_adaptive {
        display: none;
    }
    .constructor_adaptive button {
        width: 100%;
    }
    .hide_delete_step_button_wrap {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .delete_step {
        margin: 0;
    }
    .get_quiz-link_wrap {
        width: 100%;
    }
    .get_quiz-link_wrap .get_link {
        font-size: 0;
    }
    .get_quiz-link_wrap .get_link img {
        margin: 0;
    }
    .save_quiz_data_button_wrap {
        width: 100%;
    }
    .save_quiz_data_button_wrap .save_quiz_data {
        font-size: 0;
    }
    .save_quiz_data_button_wrap .save_quiz_data img {
        margin: 0;
    }
    .constructor_body .wrap_scale {
        height: auto;
    }
    .constructor_body .wrap_scale .constructor-body_slide{
        display: none;
    }
    .open_quiz_on_mobile {
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 16px 8px;
        background: var(--color-main-blue);
        border: 1px solid var(--color-main-blue);
        border-radius: 8px;
        color: var(--color-main-white);
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        text-align: center;
        writing-mode: tb;
        cursor: pointer;
        overflow: hidden;
    }
    .c-ctrl_main-link nav a {
        font-size: 11px;
    }
}

</style>