<template>
    <div>
        <!-- Контейнер для редактора Quill -->
        <div id="editor"></div>
    </div>
</template>

<script>
import { ref, watch, onMounted, nextTick } from 'vue';
import Quill from 'quill';

export default {
    name: 'QuillEditor',
    props: {
        stepData: {
            type: Object,
            required: true,
        },
    },
    setup(props, { emit }) {
        const editorContent = ref('');
        const toolbarOptions = ref([
            [{ header: [2, 3, false] }],
            ['|'],
            ['bold', 'italic', 'underline', 'strike', { 'background': [] }, 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ 'direction': 'rtl' }, { 'align': [] }],
            ['clean'],
        ]);

        let quill;
        let isQuillUpdating = false;

        onMounted(async () => {
            console.log('QuillEditor mounted. Initial stepData.description:', props.stepData.description);

            quill = new Quill('#editor', {
                theme: 'snow',
                modules: {
                    toolbar: toolbarOptions.value,
                },
                clipboard: {
                    matchers: [
                        
                    ]
                }
            });

            // Ждём завершения рендера
            await nextTick();

            // Принудительно вставляем начальное содержимое
            if (props.stepData.description) {
                setEditorContent(props.stepData.description);
            }

            // Отслеживаем изменения текста в Quill
            quill.on('text-change', () => {
                if (isQuillUpdating) return;

                const newContent = quill.root.innerHTML;
                console.log('Text changed in Quill. New content:', newContent);

                if (newContent !== editorContent.value) {
                    editorContent.value = newContent;
                    emit('update:description', newContent);
                    console.log('Emitted update:description with content:', newContent);
                }
            });
        });

        // Функция для принудительного обновления содержимого Quill
        function setEditorContent(content) {
            quill.clipboard.dangerouslyPasteHTML(content);
            editorContent.value = quill.root.innerHTML;
            console.log('Initial content set in Quill:', editorContent.value);
        }

        // Следим за изменениями в props.stepData.description
        watch(
            () => props.stepData.description,
            (newDescription) => {
                console.log('stepData.description updated. New value:', newDescription);

                if (!quill || newDescription === editorContent.value) {
                    console.log('No update needed. The new description matches editor content.');
                    return;
                }

                // Принудительно обновляем содержимое Quill
                isQuillUpdating = true;
                setEditorContent(newDescription);
                isQuillUpdating = false;
            }
        );

        return {
            editorContent,
            toolbarOptions,
        };
    },
};
</script>
