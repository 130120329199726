<template>
    <span class="dev_pan">
      {{ typeServer }}
    </span>
</template>
  
<script>
export default {
  props: {
    typeServer: {
      type: String,
      required: true,
    },
  }
}
</script>
  
<style scoped>
  .dev_pan {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: black;
    color: chartreuse;
    font-weight: 700;
    padding: 3px;
  }
</style>
  